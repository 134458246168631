export default {
  common: {
    accessDenied: 'Access Denied',
    pleaseSelect: 'Please Select',
    pleaseEnter: 'Please Enter',
    operations: 'Operations',
    Pleaseenter: 'Please Enter',
    StartDate: 'Start Date',
    EndDate: 'End Date',
    detail: 'Detail',
    edit: 'Edit',
    Cancel: 'Cancel',
    Confirm: 'Confirm',
    next: 'Next step',
    Next: 'Next step',
    Search: 'Search',
    Reset: 'Reset',
    clear: 'Clear',
    save: 'Save',
    save2: 'confirm No error',
    modify: 'Modify',
    see: 'View',
    back: 'Back',
    upload: 'Upload',
    startDate: 'Start Date',
    endData: 'End Date',
    toDate: 'To',
    index: 'No.',
    delete: 'Delete',
    nofile: 'No Certificate file',
    view: 'View',
    status: 'Status',
    noData: 'No Data',
    noMessage: 'No Message',
    noFile: 'No file',
    downloadPDF: 'Download PDF',
    operateSuccessfully: 'Operate Successfully',
    submit: 'Submit',
    agree: 'Agree',
    close: 'Close',
    tips: 'Tips',
    tipsText: 'If it needs to be modified, please contact the business acceptor by email/telephone',
    tipsText2: 'Confirm draft certificate?',
    outLogin: 'Exit succeeded',
    exit: 'Exit',
    exitText: 'Are you sure you want to exit the current user?',
    AccountInformation: 'Account Information',
    ChangePassword: 'Change Password',
    LogOut: 'Logout',
    permission: 'Page not found',
    permissiontext: 'A non-existent link has been opened, please check the accessibility of the current link.',
    BacktoHome: 'Back to Home',
    Relogin: 'Re login',
    BackPage: 'Back to previous page',
    DetailedIntroduction: 'Details',
    unknownError: 'unknown error',
    confirmDelete: 'Are you sure you want to delete it?',
    confirmDeleteAll: 'Are you sure you want to clear everything?',
    exclusiveWaiter: 'Dedicated customer service',
    dear: 'Dear ',
    colon: ':',
    comma: ',',
    customer: 'Customer',
    myRole: 'Hello! I am your exclusive customer service ',
    sir: ' sir',
    miss: 'miss',
    waiterDetail: 'sir/miss,and will serve you whole heartedly,thank you!',
    tell: 'TELL',
    QQ: 'QQ',
    mobilePhoneOrWechat: 'Mobile phone/wechat',
    sure: 'Sure',
    nothing: 'Nothing',
    logo_tetx: 'Online business handling system',
    perfect: 'Your account information needs to be completed. In order to ensure the smooth approval process, please complete the account information first, and then initiate the application and apply for the relevant certificate.',
    clickToPerfect: 'Click to perfect →',
    warning: 'Warning',
    pleaseSelectCoun: 'Select Country',
    pleaseSelectType: 'Select Type',
    clause: 'GENERAL CONDITIONS OF SERVICE',
    cal1: 'World Standardization Certification & Testing Group (Shenzhen) Co. , Ltd. ( WSCT for short) , World Standard Testing and Certification Co., Ltd. (Hong Kong ), World Standard Pengcheng Commodity Inspection Technical Service (Shenzhen) Co. , Ltd. , World Standard Testing Service (Meizhou) Co. , Ltd. , World Standard Testing and Certification Co., Ltd. Certification (Shaoxing) Co. , Ltd. Zhongwei Testing and Certification (Shenzhen) Co. , Ltd. , Shenzhen World Standard Metrology Co. , Ltd. , Ring Standard Testing and Certification (Shenzhen) Co., Ltd., Shenzhen World Standard Testing and Certification HoldingsCo., Ltd. (including all its affiliates, hereinafter referred to as the "Company ") will, in accordance with and comply with the terms and conditions contained herein (hereinafter referred to as "theConditions"), upon request to individuals or institutions (hereinafter referred to as the "Company") "Principal") to provide the Services . Unless otherwise notified in a written agreement between the Company and the MainServices, the Services provided by the Company shall begoverned by and bound by all the terms of these Conditions .',
    cal2: '1. GENERAL',
    cal3: '(a) Unless otherwise agreed in writing or except where they are at variance with(i) the regulations governing services performed on behalf of governments, government bodies or any other publicentity or (ii) the mandatory provisions of local law, all offers or services and all resulting contractual relationship(s) between any of the affiliated companiesof WSCT or any of their agents (eacha “Company”) and Client (the “ContractualRelationship(s)”) shall be governed by these general conditions of service (hereinafter the “General Conditions”).',
    cal4: '(b) The Company may perform servicesfor persons or entities (private, public or governmental) issuing instructions (hereinafter, the “Client”).',
    cal5: '(c) Unless the Company receives prior written instructions to the contrary from Client, no other party is entitled to give instructions, particularly on the scope of the services or the delivery of reports or certificates resulting therefrom (the “Reports of Findings”).Client hereby irrevocably authorises the Company to deliver Reports of Findings to a third party where so instructed by Client or, at its discretion,where it implicitly follows from circumstances, trade custom, usage or practice.',
    cal6: '2. PROVISION OF SERVICES',
    cal7: '(a) The Company will provide services using reasonable care and skill and in accordance with Client’s specific instructions as confirmed by the Company or, in the absence of such instructions:',
    cal701: '(1) the terms of any standard order form or standard specification sheet of the Company; and/or',
    cal702: '(2) any relevant trade custom, usage or practice; and/or',
    cal703: '(3) such methods as the Company shallconsider appropriate on technical, operational and/or financial grounds.',
    cal8: '(b) Information stated in Reports of Findings is derived from the results of inspection or testing procedures carriedout in accordance with the instructionsof Client, and/or our assessment of such results on the basis of any technical standards, trade custom or practice, or other circumstances which should in our professional opinion be taken into account.',
    cal9: '(c) Reports of Findings issued further to the testing of samples contain the Company’s opinion on those samples only and do not express any opinion upon the lot from which the samples were drawn.',
    cal10: '(d) Should Client request that the Company witness any third party intervention, Client agrees that the Company’s sole responsibility is to be present at the time of the third party’s intervention and to forward the results, or confirm the occurrence, of the intervention. Client agrees that the Company is not responsible for the condition or calibration of apparatus, instruments and measuring devices used, the analysis methods applied, the qualifications,actions or omissions of third party personnel or the analysis results.',
    cal11: '(e) Reports of Findings issued by the Company will reflect the facts as recorded by it at the time of its intervention only and within the limits of the instructions received or, in the absence of such instructions, within the limits of the alternative parameters applied as provided for in clause 2(a). The Company is under no obligation to refer to, or report upon, any facts or circumstances which are outside the specific instructions received or alternative parameters applied.',
    cal12: '(f) The Company may delegate the performance of all or part of the services to an agent or subcontractor and Client authorises Company to disclose all information necessary for such performance to the agent or subcontractor.',
    cal13: '(g) Should Company receive documents reflecting engagements contracted between Client and third parties or third party documents, such as copies of sale contracts, letters of credit, bills of lading, etc., they are considered to be for information only, and do not extend or restrict the scope of the services or the obligations accepted by the Company.',
    cal14: '(h) Client acknowledges that the Company,by providing the services, neither takes the place of Client or any third party, nor releases them from any of their obligations, nor otherwise assumes, abridges, abrogates or undertakes to discharge any duty of Client to any third party or that of any third party to Client.',
    cal15: '(i) All samples shall be retained for a maximum of 3 months or such other shorter time period as the nature of the sample permits and then returned to Client or otherwise disposed of at the Company’s discretion after which time Company shall cease to have any responsibility for such samples.Storage of samples for more than 3 months shall incur a storage charge payable by Client. Client will be billed a handling and freight fee if samples are returned. Special disposal charges will be billed to Client if incurred.',
    cal16: '3. OBLIGATIONS OF CLIENT',
    cal17: 'The Client will:',
    cal18: '(a) ensure that sufficient information, instructions and documents are given in due time (and, in any event not later than 48 hours prior to the desired intervention) to enable the required services to be performed;',
    cal19: '(b) procure all necessary access for the Company’s representatives to the premises where the services are to be performed and take all necessary steps to eliminate or remedy any obstacles to, or interruptions in, the  performance of the services;',
    cal20: '(c) Supply, if required, any special equipment and personnel necessary for the performance of the services;',
    cal21: '(d) ensure that all necessary measures are taken for safety and security of working conditions, sites and installations during the performance of services and will not rely, in this respect, on the Company’s advice whether required or not;',
    cal22: '(e) inform Company in advance of any known hazards or dangers, actual or potential, associated with any order or samples or testing including, for example, presence or risk of radiation, toxic or noxious or explosive elementsor materials, environmental pollution or poisons;',
    cal23: '(f) Fully exercise all its rights and discharge all its liabilities under any relevant sales or other contract with a third party and at law.',
    cal24: '4. FEES AND PAYMENT',
    cal25: '(a) Fees not established between the Company and Client at the time the order is placed or a contract is negotiated shall be at the Company’s standard rates (which are subject to change) and all applicable taxes shall be payable by Client.',
    cal26: '(b) Unless a shorter period is established in the invoice, Client will promptly pay not later than 30 days from the relevant invoice date or within such other period as may be established by the Company in the invoice (the “Due Date”) all fees due to the Company failing which interest will become due at a rate of 1.5% per month (or such other rate as may be established in the invoice) from the Due Date up to and including the date payment is actually received.',
    cal27: '(c) Client shall not be entitled to retain or defer payment of any sums due to the Company on account of any dispute, counter claim or set off whichit may allege against the Company.',
    cal28: '(d) Company may elect to bring action for the collection of unpaid fees in any court having competent jurisdiction.',
    cal29: '(e) Client shall pay all of the Company’s collection costs, including attorney’s fees and related costs.',
    cal30: '(f) In the event any unforeseen problems or expenses arise in the course of carrying out the services the Companyshall endeavour to inform Client and shall be entitled to charge additional fees to cover extra time and cost necessarily incurred to complete the services.',
    cal31: '(g) If the Company is unable to perform all or part of the services for any causewhatsoever outside the Company’s control including failure by Client to comply with any of its obligations provided for in clause 3 above the Company shall nevertheless be entitled to payment of:',
    cal3101: '(1) the amount of all non-refundable expenses incurred by the Company; and',
    cal3102: '(2) a proportion of the agreed fee equal to the proportion of the services actually carried out.',
    cal32: '5. SUSPENSION OR TERMINATION OF SERVICES',
    cal33: 'The Company shall be entitled to immediately and without liability either suspend or terminate provision of the services in the event of:',
    cal34: '(a) failure by the Client to comply with any of its obligations hereunder and such failure is not remedied within 10 days that notice of such failure has been notified to Client; or',
    cal35: '(b) any suspension of payment, arrangement with creditors, bankruptcy, insolvency, receivership or cessation of business by Client.',
    cal36: '6. LIABILITY AND INDEMNIFICATION',
    cal37: '(a) Limitation of Liability:',
    cal3701: '(1) The Company is neither an insurer nor a guarantor and disclaims all liability in such capacity. Clients seeking a guarantee against loss or damage should obtain appropriate insurance.',
    cal3702: '(2) Reports of Findings are issued on the basis of information, documents and/or samples provided by, or on behalf of, Client and solely for the benefit of Client who is responsible for acting as it sees fit on the basis of such Reports of Findings. Neither the Company nor any of its officers, employees, agents or subcontractors shall be liable to Client nor any third party for any actions taken or not taken on the basis of such Reports of Findings nor for any incorrect results arising from unclear, erroneous, incomplete,misleading or false information provided to the Company.',
    cal3703: '(3) The Company shall not be liable for any delayed, partial or total non-performance of the services arising directly or indirectly from any event outside the Company’s control including failure by Client to comply with any of its obligations hereunder.',
    cal3704: '(4) The liability of the Company in respect of any claim for loss, damage or expense of any nature and howsoever arising shall in no circumstances exceed a total aggregate sum equal to 10 times the amount of the fee paid in respect of the specific service which gives rise to such claim or US$20,000 (or its equivalent in local currency), whichever is the lesser.',
    cal3705: '(5) The Company shall have no liability for any indirect or consequential loss including without limitation loss of profits, loss of business, loss of opportunity, loss of goodwill and cost of product recall. It shall further have no liability for any loss, damage or expenses arising from the claims of any third party (including, without limitation, product liability claims) that may be incurred by the Client.',
    cal3706: '(6) In the event of any claim, Client must give written notice to the Company within 30 days of discovery of the facts alleged to justify such claim and, in any case, the Company shall be discharged from all liability for all claims for loss, damage or expense unless suit is brought within one year from:',
    cal37061: '(i) The date of performance by the Company of the service which gives rise to the claim; or',
    cal37062: '(ii) The date when the service should have been completed in the event of any alleged non-performance.',
    cal38: '(b) Indemnification: Client shall guarantee, hold harmless and indemnify the Company and its officers, employees, agents or subcontractors against all claims (actual or threatened) by any third party for loss, damage or expenseof whatsoever nature including all legalexpenses and related costs and howsoever arising relating to the performance, purported performance or non-performance, of any services.',
    cal39: '7. MISCELLANEOUS',
    cal40: '(a) If any one or more provisions of these General Conditions are found to be illegal or unenforceable in any respect,the validity, legality and enforceability of the remaining provisions shall not in any way be affected or impaired thereby.',
    cal41: '(b) During the course of providing the services and for a period of one year thereafter Client shall not directly or indirectly entice, encourage or make any offer to Company’s employees to leave their employment with the Company.',
    cal42: '(c) Use of the Company’s corporate name or registered marks for advertising purposes is not permitted without the Company’s prior written authorisation.',
    cal43: '8. GOVERNING LAW, JURISDICTION AND DISPUTE RESOLUTION',
    cal44: 'Unless specifically agreed otherwise, all disputes arising out or in connection with Contractual Relationship(s) hereunder shall be governed by the substantive laws of Switzerland exclusive of any rules with respect to conflicts of laws and be finally settled under the Rules of Arbitration of the International Chamber of Commerce by one or more arbitrators appointed in accordance with the said rules. The arbitration shall take place in Paris (France) and be conducted in the English language.',
    cal45: '9. SPECIAL CONDITION',
    cal46: 'Notwithstanding the provisions of clause 8 above, and providing that the Company and Client have their registered offices in China, all disputes arising out of or in connection with Contractual Relationship(s) hereunder shall be governed by the substantive laws of China , exclusive of any rules with respect to conflicts of laws and be finally settled under the Rules of Shanghai Arbitration Center by one or more arbitrators appointed in accordance with the said Rules, each party to bear its own costs. The arbitration shall take place in Shanghai and be conducted in the Chinese language.',
    newMessage: 'New Message',
    messageCenter: 'Message center',
    allRead: 'All read',
    noMore: 'no More',
    RMB: '人民币',
    dollar: '美元'
  },
  request: {
    401: 'Login information has expired. Please log in again',
    403: 'Permission denied',
    404: 'Request error, the resource was not found',
    405: 'Request method not allowed',
    408: 'Request timeout',
    500: 'Server side error',
    501: 'Network not implemented',
    502: 'Network error',
    503: 'Service unavailable',
    504: 'Network timeout',
    505: 'The http version does not support this request',
    linkError: 'Connection error',
    timeout: 'Server response timed out, please try again later',
    linkServerError: 'Failed to connect to server'
  },
  home: {
    hello: 'Hello! Welcome to the WSCT online business processing system.',
    text1: 'World Standardization Certification & Testing Group(ShenZhen) Co Ltd.(WSCT for short) provides global one-stop public testing, certification, identification and inspection service platform solutions for many industries and products.',
    text2: 'Terms in the application operation guide:',
    Myapplication: 'My application',
    Mycertificate: 'My certificate',
    Draftapplication: 'Draft application',
    text3: 'Returned applications (applications requiring modification or supplementary information)',
    text4: 'Application in process',
    text5: 'Certificate in normal state',
    text6: 'Certificates expiring in 1 month',
    order: 'order',
    Terminology: 'Terminology',
    Applicationstatus: 'Application status',
    ApplicationFormStatus: 'ApplicationForm status',
    Application1: 'To be processed: ',
    Application1_2: 'An order submitted but not yet accepted',
    Application2: 'Return: ',
    Application2_2: 'Is returned, need to modify the order or supplementary information',
    Application3: 'Processing: ',
    Application3_2: 'In process, normal execution process',
    Application4: 'Issued: ',
    Application4_2: 'The certificate has been issued and the process is complete',
    Application5: 'Cancel: ',
    Application5_2: 'Confirm the cancellation',
    Paymentstatus: 'Payment status',
    Payment1: 'To be quoted: ',
    Payment0: 'Not quoted',
    Payment1_2: 'It has been accepted, the accepting party has not quoted',
    Payment2: 'To be paid: ',
    Payment2_2: 'The quotation has been made and the customer has not yet paid (including the transfer has been made in the bank and has not been confirmed by the financial department of the acceptor)',
    Payment3: 'Partial payment: ',
    Payment3_2: 'After payment, there is a difference between the amount confirmed by the receiver financial department and the amount in the entrustment document',
    Payment4: 'Payment completed: ',
    Payment4_2: 'Fully settled',
    ApplicationStage: 'Application Stage',
    stage1: 'System certification application stage',
    stage2: 'Export certification application stage',
    stage3: 'Delegated inspection application stage',
    PendingApplications: 'Pending Applications',
    CertificationAcceptance: 'Certification Acceptance',
    Typetest: 'Typetest',
    FactoryInspection: 'Factory Inspection',
    ConformityAssessment: 'Conformity Assessment',
    CertificateIssuance: 'Certificate Issuance',
    CertificateProduction: 'Certificate Production',
    EndCertification: 'End Certification',
    FieldInspection: 'Field Inspection',
    LaboratoryTesting: 'Laboratory Testing'
  },
  myExpenses: {
    address: 'Address',
    name: 'Name',
    date: 'Date'
  },
  login: {
    AcountLogin: 'Account Login',
    MobileLogin: 'Mobile Login',
    signIn: 'Login',
    register: 'Register',
    Forgetpassword: 'Forget password',
    userPlaceholder: 'User',
    userError: 'Please input a user name',
    PWPlaceholder: 'Password',
    PWError: 'Please input a password',
    PWnum: 'Password must least 6 digits',
    Codelaceholder: 'Code',
    CodeError: 'Please input a code',
    errorcode: 'Code error',
    mobilePlaceholder: 'Mobile',
    mobileError: 'Please input mobile',
    mobilenum: 'input 11 digit mobile phone number',
    smsPlaceholder: 'SMS Code',
    smsError: 'Please input sms code',
    smsGet: 'Get SMS Code',
    smsSent: 'SMS sent to mobile number',
    noAccount: 'No account?',
    createAccount: 'Create a new account',
    wechatLoginTitle: 'QR code sign in',
    wechatLoginMsg: 'Please use wechat to scan and log in',
    wechatLoginResult: 'Scanned | Please click authorize login in the device',
    logsuccess: 'Login success',
    mosuccess: 'Modify success',
    wholeError: 'Please input mobile or email',
    third: 'Third-party logins',
    sweep: 'Scan Alipay',
    WeChat: 'WeChat',
    Alipay: 'Alipay',
    remind: 'Your Alipay account has not yet been bound to the system account. You can do the following',
    Yes: 'Existing account, log in',
    No: 'No account, go register',
    telResetPassword: 'SMS reset password',
    emailResetPassword: 'Email reset password',
    emailsuccess: 'Email verification code sent successfully',
    firstgetCode: 'Please obtain the verification code first',
    PLemail: 'Please enter your email address',
    emailCode: 'Email verification code',
    prompt: 'prompt',
    getSMStips: 'If you cannot receive the verification code, please log in with your account password or email first, and then modify the area code and phone number in the personal information interface to facilitate quick login using your phone number.'
  },
  myProfile: {
    username: 'Login name',
    headimg: 'Head portraits',
    name: 'Name',
    sex: 'Sex',
    customerIds: 'Identity',
    mobile: 'Mobile',
    email: 'Email',
    WeChatOrQQ: 'WhatsAPP',
    selectCountry: 'Country',
    selectProvinceCityRegion: 'Province city region',
    addressDetail: 'Detailed address',
    english: '(English)',
    postcode: 'Email',
    companyName: 'Company name',
    businessLicense: 'Upload License',
    corporate: 'Principal',
    companyPhone: 'Company phone',
    companyFax: 'Fax',
    remark: 'Remark',
    man: 'man',
    woman: 'woman',
    Bailor: 'Bailor',
    Manufacturer: 'Manufacturer',
    Factory: 'Factory',
    Agency: 'Agency',
    editMyProfile: 'Edit my profile',
    uploadFile: 'Drop file here or click to upload',
    uploadFormat: 'jpg/jpeg/png/pdf, maximum 20M',
    imgType: 'Picture must be jpg/png/jpeg/gif format!',
    imgSize: 'Picture size can not exceed 1MB!',
    licenseType: 'file must be jpg/png/jpeg/gif/pdf format!',
    licenseLength: 'A maximum of five files can be uploaded to the business license',
    licenseSize: 'file size can not exceed 3MB!',
    validateUserName: 'Please input Login Name',
    validateUserNameLength: 'Login Name contains 2 to 16 characters',
    validateUserNameFormat: 'Login Name must be number or English',
    validateName: 'Please input name',
    validateNameLength: 'Name contains 2 to 100 characters',
    validateNameFormat: 'Name must be Chinese or English',
    validateMobile: 'Please input Mobile',
    validateMobileLength: 'Mobile contains 8 to 15 characters',
    validateMobileFormat: 'Please enter the correct mobile',
    validatePass: 'Please input the password',
    validatePassLength: 'The password must contain at least 6 characters',
    validatePassFormat: '"PassWord" must contain digits, letters, and special symbols !"#$%&\'()*+,-./:;<=>?@[]^_`{|}~',
    validateEmail: 'Please input email',
    validateEmailFormat: 'Please enter the correct email',
    validateZipCode: 'Please input zipcode',
    validateZipCodeFormat: 'Please enter a 4-6 number zipcode',
    validateSex: 'Please input sex',
    validateIdentity: 'Please input identity',
    validateCountry: 'Please select the country',
    validateProvince: 'Please select the province city district',
    validateAddress: 'Please input address detail',
    validateCompany: 'Please input company name',
    validateCompanyLegal: 'Please input principal',
    validateCorporate: 'Please input corporate',
    validateLicense: 'Please upload business license',
    completeMyInfo: 'Please improve the account information content',
    completeMyInfoTip: 'Please complete the data before applying for business',
    Codelaceholder: 'Please input a code',
    Verificationcodesent: 'Verification code sent',
    telcomfirm: 'Mobile number verification',
    emailcomfirm: 'Email verification',
    changeTel: 'Modify',
    changeEmail: 'Modify email',
    PLcomfirmWay: 'Please select the verification method',
    newTel: 'New phone number',
    telCode: 'Mobile number verification code',
    emailbox: 'Email',
    mobiletel: 'Mobile phone',
    newEmail: 'New email',
    emailcode: 'Email verification code',
    Scancodeverification: 'Scan code verification',
    hasPhoneCode: 'The verification code has been sent to your phone, please check it carefully',
    getCode: 'Get SMS Code',
    hasEmailCode: 'We have sent the verification code to your email. Please check it carefully',
    firstgetCode: 'Please obtain the verification code first',
    changeItem: 'Changing password, email, and phone number requires verification',
    password: 'Password',
    PLinput: 'Enter',
    change: 'Modify',
    personmessage: 'Contact information',
    identity: 'Identity documents',
    ID: 'ID card',
    passport: 'Passport',
    IDnum: 'Identity document',
    front: 'front',
    opposite: 'opposite',
    passportimg: 'Passport homepage',
    supportsize: 'jpg/jpeg/png/pdf, maximum 20M',
    compouyinfo: 'Company information',
    compounenyNum: 'License No.',
    PLselect: 'Please select',
    PLuploadfront: 'Please upload a photo of the front of your ID card',
    PLuploadopposite: 'Please upload a photo of the back of your ID card',
    PLuploadpassportimg: 'Please upload a photo of the passport homepage',
    acountInfo: 'Account information',
    register: 'Register',
    Existingaccount: 'Existing account? Go log in',
    Login: 'Login',
    Pleasefill: 'Please fill in the channel information and save it before applying for business',
    confirmpassword: 'Confirm password',
    Available: 'Available for login',
    notnameNumber: 'The name cannot be all digits',
    PLidNumber: 'Please enter ID number number',
    regitererr: 'login has failed',
    Contact: 'Contact information',
    WechatOther: 'Wechat /WhatsApp/ Others',
    PLtel: 'Please enter the number',
    PLidNo: 'Enter the ID number',
    idpicture: 'Id photo',
    companynum: 'Company headcount',
    companywebsite: 'Company website/official website',
    companyquality: 'The number of qualified persons in the company',
    PLselecttype: 'Please select a type',
    add: 'Add',
    delete: 'Delete',
    companysize: 'Company qualification',
    // 公司简介
    companyintro: 'Company profile',
    source: 'source',
    WSCT: 'WSCT official website',
    network: 'Network',
    WSCTintro: 'Introduction of WSCT staff',
    intro: 'Enter the name of the introducer',
    other: 'Other',
    selectid: 'Please select an identification document',
    uploadid: 'Please upload your ID photo',
    inputid: 'Please enter your ID number',
    uploadidcard: 'Please upload the first page of your ID card',
    selectcertificate: 'Select a qualification type',
    selectcertificatename: 'Select a qualification name',
    inputnum: 'Please enter number of people',
    inputcompanynum: 'Please enter the total number of people in the company',
    selectcompany: 'Please select company qualification',
    inputcompanyintro: 'Please enter company profile',
    inputWSCTintro: 'Please enter the introduction employee name',
    inputWSCTintroError: 'You cannot enter a referring employee name',
    fileTypeError: 'File type error',
    fileSizeError: 'File size error',
    selectcertificateError: 'Please select a qualification certificate',
    lengthError: 'The length must be less than 50',
    introlebal: 'Company profile',
    PLsource: 'Please enter the source',
    account: 'Please enter your account number',
    realname: 'Please enter your name',
    plemail: 'Please enter your email address',
    unifiedsocial: 'Please enter the license number',
    companyname: 'Please enter the company name',
    legalperson: 'Please enter the name of the legal representative',
    fax: 'Please enter the fax number',
    address: 'Please enter the address',
    zipcode: 'Please enter your zipcode',
    companyallnum: 'Please enter the number of employees in the company',
    website: 'Please enter the website address',
    wechatqq: 'Please enter WeChat',
    idcard: 'Please enter ID number number',
    remarks: 'Please enter the remark',
    websiteError: 'Please enter the correct URL',
    registerInfo: 'Confirmation of registration information',
    person: 'people',
    // 渠道类型
    channelType: 'Channel type',
    // 输入公司关键词点击回车搜索公司
    searchCompany: 'Enter keywords to search compony',
    // 请选择渠道类型
    selectChannelType: 'Please select channel type',
    // 公司
    company: 'Company',
    // 个人
    personal: 'Personal',
    // WSCT渠道加盟
    WSCTchannel: 'WSCT Channel Join',
    // Enquriy Hotline 86-0755-269962306 26996053(Overseas)  Email fengbing.wang@wsct-cert.com  Mobile phone/WeChat/Skype 13823277946
    consult: 'Enquiry hotline +86-755-26992306 26996053  Email fengbing.wang',
    consultend: 'wsct-cert.com   Mobile Number/WeChat/Skype 13823277946 ',
    // 微信13823277946
    wechattel: 'WeChat 13823277946',
    // 非凡事业，立即启航！
    start: 'Extraordinary career, sail now!',
    // 请输入身份证件号码
    pleidcard: 'Please enter your ID number',
    selectChannelTypea: 'Please select a channel type',
    // 请输入公司电话
    plecompanyphone: 'Please enter the company phone number',
    // 请选择手机所属国家区号
    plecountry: 'Please select the country code of the mobile phone',
    // 亲输入公司联系人电话
    plecontactphone: 'Please enter the contact phone number of the company',
    // 个人资质
    personalqualification: 'Personal qualification',
    // 个人简介
    personalintro: 'Biography',
    // 个人简历
    personalresume: 'Vita',
    // 点击或拖拽上传
    clickOrDrag: 'Click or drag to upload',
    // 上传格式为doc/docx/pdf,大小不得超过10MB
    uploadFileType: 'Upload a maximum of 1 file in pdf/docx/doc format,and the size should not exceed 10MB.',
    // 请输入省市区
    pleprovince: 'Please enter the province',
    // 法人代表手机号
    componyhumantel: 'Legal mobile',
    // 上传
    upload: 'Upload',
    // 请输入验证码
    plecode: 'Please enter the verification code',
    // 请输入公司电话
    plecompanytel: 'Please enter the company phone number',
    // 请输入公司邮箱
    plecompanyemail: 'Please enter the company email address',
    // 请输入公司网址
    plecompanywebsite: 'Please enter the company website address',
    // 请上传资质图片
    pleuploadcertificate: 'Please upload the qualification certificate',
    // 请完善已选择的个人资质项
    pleequalqualification: 'Please complete the selected personal qualification item',
    // 请上传个人简历
    pleuploadresume: 'Please upload your resume',
    // 超过上传限制
    uploadlimit: 'Exceed the upload limit',
    // 预览
    preview: 'Preview',
    // 请完善省市区
    plecompleteprovince: 'Please complete the province',
    // 公司名称不能少于5个字
    companynamelength: 'The company name cannot be less than 5 characters'
  },
  register: {
    Accountname: 'Account name',
    Accountlaceholder: 'Account name',
    nameError: 'Please input a account name',
    Password: 'Password',
    PWPlaceholder: 'Password',
    PWError: 'Please input a password',
    Passwords: 'Confirm password',
    PWPlaceholders: 'Repeat Password',
    PWErrors: 'Please input the password again',
    inconsistent: 'passwords entered twice are inconsistent',
    Name: 'Name',
    Namelaceholder: 'Name',
    NameError: 'Please input a Name',
    mobile: 'Phone number',
    mobilePlaceholder: 'Phone number',
    mobileError: 'Please input a phone number',
    email: 'Email',
    emailPlaceholder: 'Email',
    emailError: 'Please input a Email',
    read: 'Read and agreed',
    readError: 'Please read and agreed the agreement',
    agreement: '《Terms of Service》',
    Backlogin: 'Back login',
    register: 'Register',
    Resuccess: 'Register success',
    pleaseSelectMethod: 'Please select a method for verification',
    moblieVerificationCode: 'Mobile verification code',
    emailVerificationCode: 'Email verification code',
    moblieVerification: 'Mobile number verification',
    emailVerification: 'Email verification',
    pleaseInputCode: 'Please enter the verification code',
    pleaseGetCode: 'Please obtain the verification code first',
    pleaseTrueMobile: 'Please enter the correct phone number',
    alreadyAccount: 'Existing account? Go log in'
  },
  setting: {
    oldPassword: 'Old password',
    oldPWPlaceholder: 'Please input a old password',
    oldPWError: 'Please input a old password',
    Password: 'New Password',
    PWPlaceholder: 'Please input a password',
    PWError: 'Please input a password',
    Passwords: 'Confirm password',
    PWPlaceholders: 'Please input the password again',
    PWErrors: 'Please input the password again',
    inconsistent: 'passwords entered twice are inconsistent',
    changePassword: 'Change password',
    Modificationsuccess: 'Modification success',
    PLresetWay: 'Please select the verification method',
    phone: 'mobile phone',
    telCode: 'Mobile number verification code',
    hastelCode: 'The verification code has been sent to your phone, please check it carefully',
    getCode: 'Get SMS Code',
    email: 'Email',
    emailCode: 'Email verification code',
    hasemailCode: 'We have sent the verification code to your email. Please check it carefully',
    PLinput: 'Please enter content',
    tel: 'Mobile number verification',
    emailbox: 'Email verification',
    hasCode: 'Verification code sent',
    firstgetCode: 'Please obtain the verification code first'
  },
  rfclist: {
    Number: 'NO.',
    HSCode: 'HSCode',
    ApplicationFor: 'Application For',
    Title: 'Title',
    TitlePlaceholder: 'Please input a Title',
    Importer: 'Importer',
    ImporterPlaceholder: 'Please input a Importer',
    Exporter: 'Exporter',
    ExporterPlaceholder: 'Please input a Exporter',
    Status: 'Status',
    CreatedTime: 'Created Time',
    PaymentStatus: 'Payment Status',
    Quotation: 'Quotation',
    QuotationPreview: 'Quotation Preview',
    QuotationConfirmation: 'Quotation Confirmation',
    PlaceOfInspection: 'Place Of Inspection',
    RFCNo: 'RFC No.',
    DraftCertificate: 'Draft Certificate',
    DraftPreview: 'Draft Preview',
    DraftReview: 'Draft Review',
    OthersAction: 'OthersAction',
    Keywords: 'Keywords',
    KeywordsPlaceholder: 'Please input a Keywords',
    Country: 'Country',
    ViewQuotation: 'ViewQuotation',
    Preview: 'Preview',
    Totalamount: 'Total Amount',
    AmountPaid: 'Amount Paid',
    Unpaid: 'Unpaid',
    AccepterEmail: 'Accepter/Email',
    DraftConfirmation: 'Draft Confirmation',
    DraftView: 'Draft View'
  },
  certificate: {
    Number: 'Number',
    RFCNo: 'RFC No.',
    CertificateNo: 'Certificate No',
    Title: 'Title',
    Importer: 'Importer',
    Exporter: 'Exporter',
    CertificateStatus: 'Certificate Status',
    ApplicationFor: 'Application For',
    IssueDate: 'Issue Date',
    Route: 'Route',
    CreatedTime: 'Created Time',
    CertificateType: 'Certificate Type',
    Action: 'Action',
    Status: 'Status',
    CertificateDetails: 'Certificate Details',
    CompanyName: 'Company Name'
  },
  customsClearance: {
    applicationDrafts: {
      title: 'Title',
      exporter: 'Exporter',
      applicationFor: 'Application For',
      importer: 'Importer',
      placeOfInspection: 'Place Of Inspection',
      createdTime: 'Created Time',
      certificateType: 'Certificate Type'
    },
    myApplicationForm: {
      Select: 'Select the corresponding service',
      country: 'Country Destination',
      createMode: 'Create Mode',
      createForm: 'Create Form',
      importApplicationData: 'Example Import historical application data',
      choose: 'Choose',
      pleaseChoose: 'Please click the button to make a selection',
      title: 'Title',
      applicationFor: 'Application For',
      hasIDFNumber: 'Has IDF Number',
      IDFNo: 'IDF No.',
      UcrNo: 'Ucr No.',
      mailCertificateCollection: 'Mail Certificate Collection',
      shipmentMethod: 'Shipment Method',
      cargoType: 'Cargo Type',
      containerInfo: 'Container Info',
      placeOfLoading: 'Place&Port Of Loading',
      placeOfDischarge: 'Place&Port Of Discharge',
      countryOfSupply: 'Country Of Supply',
      countryOfOrigin: 'Country Of Origin',
      quantityDelivered: 'Quantity Delivered',
      full: 'Full',
      partial: 'Partial',
      tel: 'Tel',
      fax: 'Fax',
      qq: 'QQ',
      email: 'Email',
      hotline: 'Key customer hotline mobile phone/wechat',
      address: 'Address',
      contactInfo: 'If you need to do this business, please contact us at the following ways',
      addressDetail: 'Building A-B, Baoli\'an Industrial Park, No.58 and 60, Tangtou Avenue, Shiyan Street, Bao\'an District, Shenzhen City, Guangdong Province, China.',
      titleitem: 'You give your application a name so that you can search and distinguish between different applications, and the application titles of the two applications cannot be duplicated.',
      titleitemone: 'You can name it in a way that is convenient for your own statistics and query: 1) Company name abbreviation - product abbreviation - number; 2) Trademark - Date - Number.'
    },
    productConformity: {
      placeOrdersOnline: 'Place orders online and manage orders quickly',
      draftsDetailTitle: 'Details of Draft Application',
      statusDetailTitle: 'Details of application processing',
      businessGuide: 'Business Application Guide',
      applicationGuide: 'Application Guide',
      applicationGuideDownload: 'Application Guide Download',
      importantNote: 'Important note: This form must be completed and submitted to WSCT prior to shipment',
      FillOutTheFormOnlineHint: 'Fill out the form online or call the historical data or download the template to fill in and then submit',
      basicContentsForm: 'Fill in the basic content of inspection application form',
      uploadAttachmentAndInvoice: 'Upload Attachment and the proforma invoice of the inspection application form',
      uploadAttachmentAndInvoice2: 'Attachment and the proforma invoice of the inspection application form',
      uploadAttachment: 'Upload the attachment required for inspection application form',
      uploadAttachment2: 'The attachment required for inspection application form',
      pleaseEnterParties: 'Please enter the details of the parties',
      pleaseEnterParties2: ' The details of the parties',
      finalInvoice: 'Final Invoice or Proforma is mandatory',
      itemsSending: "Please add the items you're sending (At least one item is mandatory)",
      itemsSending2: "The items you're sending (At least one item is mandatory)",
      createType: 'Reference information',
      selectFormTypeTitle: 'Reference information',
      applicationFormTitle: 'Fill in the basic content of inspection application form',
      titlePrompt: 'It is the name you give to your application, so that you can easily search and distinguish between different applications. The application titles of two applications cannot be duplicated. You can name it in a way that is convenient for your own statistics and queries, and should not be too long. For example: 1) Company name abbreviation product abbreviation number; 2) Trademark date number',
      applicationFormTitle2: 'The basic content of inspection application form',
      uploadTitle: 'Upload  Attachment and the proforma invoice of the inspection application form',
      downloadTemplate: 'Download Template',
      import: 'Import',
      saveDraft: 'Save as draft',
      addInvoice: 'Add Invoice',
      invoiceText: 'Invoice',
      invoiceRemind: 'At least one invoice information must be added',
      upload: 'Upload',
      invoiceNO: 'Invoice NO.',
      invoiceFile: 'Invoice File',
      invoiceDate: 'Invoice Date',
      currency: 'Currency',
      amount: 'Amount',
      incoterm: 'Incoterm',
      uploadText: 'Click Or drag file here ',
      upload2: 'upload',
      uploadTip: 'jpg/gif/png/bmp/doc/docx/xlsx/xls/pdf/txt/zip/tar/rar files with a size less than 200MB',
      uploadTip2G: 'jpg/gif/png/bmp/doc/docx/xlsx/xls/pdf/txt/zip/tar/rar files with a size less than 2G',
      uploadDocument: 'Upload Transaction Document',
      documentText: 'Transaction Document',
      documentTip: 'Packing List  / IDF Document / Quality Document / Others',
      documentMessage01: '1、The size of each uploaded attachment should not exceed 200M; otherwise, it cannot be submitted normally.',
      documentMessage001: '1、The size of each uploaded attachment should not exceed 2G; otherwise, it cannot be submitted normally.',
      documentMessage02: '2、The supported file types are jpg、gif、png、bmp、doc、docx、xlsx、xls、pdf、txt、zip、tar、rar.',
      fileType: 'File Type',
      fileName: 'File Name',
      size: 'Size',
      remarks: 'Remarks',
      companyName: 'Company Name',
      companyNameEn: 'Company Name(EN)',
      country: 'Country',
      city: 'City',
      address: 'Address',
      zipCode: 'Zip Code',
      contactName: 'Contact Name',
      telephone: 'Telephone',
      telephonePlaceholder: 'Example: +86-10-8888888 or 86-010888888',
      email: 'Email',
      fax: 'Fax',
      payWSCTService: 'Pay for WSCT service',
      exporterDetails: 'Seller/Exporter Details',
      placeOfInspection: 'Place of Inspection',
      chooseHistoricalData: 'Choose historical data',
      importerDetails: 'Buyer/Importer Details',
      warehouseName: 'Warehouse Name',
      warehouseNameEn: 'Warehouse Name(EN)',
      zoneProvince: 'Zone/Province',
      GoodsAvailableDate: 'Goods Available For Inspection As From',
      pleaseSelectRfcType: 'Please select the Application For first',
      beneficiary: 'Beneficiary',
      addProduct: 'Add Product',
      HSCode: 'HSCode',
      brand: 'Brand',
      modelNumber: 'Model Number',
      quantity: 'Quantity',
      unitPrice: 'Unit Price',
      unit: 'Unit',
      goodsDescription: 'Goods Description',
      manufacturerName: 'Manufacturer Name',
      manufacturerAddress: 'Manufacturer Address',
      countryOfOrigin: 'Country Of Origin',
      goodsCondition: 'Goods Condition',
      assemblyState: 'Assembly State',
      ApplicableStandard: 'Applicable Standard(S) & Normative References',
      brOfRegistration: 'BR Of Registration/License/Product Cert',
      correspondingCertificate: 'Corresponding Registration Certificate (SOR)/License Certificate (SOL)/Product Certificate (PC) number',
      ClearAllItems: 'Clear all items',
      getTemplate: 'Get template',
      importItems: 'Import items',
      productList: 'Product List',
      uploadProductTip: 'The imported data file is in xlsx format and no larger than 20MB',
      containerSize: 'Container Size',
      containerQuantity: 'Container Quantity',
      applyTypeItem: 'Please select an Application For',
      getIdfNo: 'Please select Has IDF Number',
      idfNo: 'Please enter IDF No.',
      ucrNo: 'Please enter UCR No.',
      needCertPerson: 'Please select Mail Certificate Collection',
      transportMode: 'Please select Shipment Method',
      packingMethod: 'Please select Cargo Type',
      containerData: 'Please enter Container Info',
      supplierCountry: 'Please select Country Of Supply',
      selectCorrectData: 'Please select the correct ',
      // 发票信息
      invoicePath: 'Please upload the invoice',
      invoiceNo: 'Please enter Invoice NO.',
      selectInvoiceDate: 'Please select Invoice Date',
      selectTradeTerm: 'Please select Incoterm',
      selectCurrency: 'Please select Currency',
      invoiceTotal: 'Please enter Amount',
      article: 'Article ',
      uploadInvoiceError: ' Failed to upload the invoice file',
      // 附件信息
      selectFileType: 'Please select a file type',
      pleaseUploadFile: 'Please upload the attachment',
      // 详细信息
      inputCompanyName: 'Please enter Company Name',
      inputCompanyNameEn: 'Please enter Company Name(English)',
      inputWarehousename: 'Please enter Warehousename',
      inputWarehousenameEn: 'Please enter Warehousename(EN)',
      countryName: 'Please enter Country',
      cityName: 'Please enter City',
      inputAddress: 'Please enter Address',
      inputContact: 'Please enter Contact Name',
      inputTelephone: 'Please enter Telephone',
      selectPostDate: 'Please select Goods Available For Inspection As From',
      inputEmail: 'Please enter Email',
      inputEmailReg: 'Please enter the correct email format',
      inputTelephoneReg: 'The value is in the format of 4 to 20 digits and special characters +-',
      inputRegionOrPrice: 'Please enter Zone/Province',
      // 产品信息
      inputHSCode: 'Please enter HSCode',
      inputBrandName: 'Please enter Brand',
      inputModel: 'Please enter Model Number',
      inputQuantity: 'Please enter Quantity',
      inputPrice: 'Please enter Unit Price',
      selectUnit: 'Please select Unit',
      inputGoodsDescription: 'Please enter Goods Description',
      inputManufacturerName: 'Please enter Manufacturer Name',
      inputManufacturerAddress: 'Please enter Manufacturer Address',
      selectOriginCountry: 'Please select Country Of Origin',
      selectFreightStatus: 'Please select Goods Condition',
      selectAssembleStatus: 'Please select Assembly State',
      selectHistory: 'Select historical application data',
      importExcel: 'xlsx files with a size less than 20MB',
      noCountryText: 'The country information is not queried',
      applicationDonotEdit: 'The status of the current certification application cannot be edited, please contact the salesman for operation.',
      addApplicationSaveSuccess: 'Automatic saving succeeded',
      addApplicationSaveError: 'Failed to save automatically',
      addApplicationSubmitSuccess: 'Submit successfully',
      addApplicationSubmitError: 'Submit failure',
      addApplicationDraftsSuccess: 'Saved draft successfully',
      addApplicationDraftsError: 'Save to draft failed',
      completeApplicationContent: 'Please complete the required contents of the certification application',
      lessAddOneProduct: 'Please add at least one product item',
      pleaseAddInvoice: 'Please add invoice information',
      improvedocumentInfo: 'Please enter the required attachment information',
      upload20Attachments: 'Tips: Upload up to 20 attachments!',
      upload100Attachments: 'Tips: Upload up to 100 attachments!',
      selectHistoryData: 'Please select historical application data',
      selectApplyType: 'Please select Application For',
      pleaseUpdateTemplate: 'Please import an excel template',
      whetherInspect: 'Whether to inspect goods remotely',
      pleaseSaveOrEdit: 'Please save or delete the attachment information in the edit state',
      maxInvoiceText: 'Upload a maximum of three invoices',
      currentApplicationCannotEdit: 'The status of the current certification application cannot be edited, please contact the salesman for operation',
      timeCannotEarlier: 'The time cannot be earlier than the current date',
      timeCannotToday: 'The time cannot exceed the current date',
      pleaseImproveProduct: 'Please improve the product information:',
      limitFilesNum: 'Upload a maximum of 5 attachments at a time',
      limitFiles10Num: 'Upload a maximum of 10 attachments at a time',
      sureSaveDraft: 'Are you sure to save the draft?',
      uploadSuccess: 'Upload successful',
      uploadError: 'Upload failed',
      toModify: 'To modify',
      haveFailDocument: ' attachments are being uploaded or uploading failed. Are you sure to submit?',
      reupload: 'Reupload',
      excelImport: 'Import Excel',
      // 运输信息
      packagingInformation: 'Packaging Information',
      packageQuantity: 'Package Quantity',
      transportContainerNumber: 'Transport Container Number',
      acid: 'ACID',
      importersTaxNumber: "Importer's Tax Number",
      exporterID: 'IDExporter ID',
      exportCountry: 'Export Country',
      exportCountryCode: 'Export Country Code',
      limitContainerNo: 'A maximum of 10 transport container numbers can be added',
      limitContainerNoItem: 'The transport container number shall be letters and numbers and shall not exceed 20 in length',
      pleaseInputTransportNum: 'Please enter the shipping container number, press Enter to add',
      pleaseInputacid: 'Please enter ACID',
      pleaseSelectpackaging: 'Please select packaging information',
      pleaseInputPackageNum: 'Please enter the packing quantity',
      pleaseInputImportersTax: "Please enter the importer's tax number",
      pleaseInputExportID: 'Please enter the exporter ID',
      pleaseSelectExportCode: 'Please select export country code',
      inputAfterEnter: 'Type and press Enter to add',
      // 加载
      loading: 'Loading...',
      submitting: 'Submitting...',
      uploading: 'Uploading...',
      // 用户条款
      userTerms: 'User terms',
      companyAddress: ', The address is ',
      ourCompany: 'Our company, ',
      userTermsStated: ', It is hereby stated that the declared products are not affected by the recalled products, nor are they unqualified or fake or shoddy products. We take responsibility for the authenticity of all declared information. At the same time, we are fully aware of the regulatory requirements of importing countries on the import of these products and are committed to complying with these requirements.',
      userTermsInfo: 'After submitting the information, WSCT will accept the application form and contact you via email as soon as possible. Please confirm whether you can be reached by this email address ',
      usreTermsInfo2: '. If not, please fill in below, multiple email address with ";" Separate.',
      // 当地商业登记号
      localBusinessRegistrationNumber: 'Local commercial registration No.',
      // 进口商/收货人是伊拉克政府部门或伊拉克相关行政部门
      importersDepartments: 'Importer/consignee is an Iraq ministry or related Iraq administration/authority',
      // 装运和检验信息：
      transportformation: 'Shipment & inspection information:',
      // 交货类型
      deliveryType: 'Type of delivery',
      transportModeone: 'Transport mode',
      // 装运方式
      loadingType: 'Shipment Method',
      // 供应国
      supplyCountry: 'Country Of Supply',
      // 预计装运日期
      estimatedLoadingDate: 'Expected date of shipment',
      // 货物供应日期
      goodsSupplyDate: 'Date of availability of goods',
      // 装运方式和进入伊拉克中部的地点（必须填写进入地点信息）：
      loadingLocation: 'Shipment Method and Points of Entry to Central Iraq (lnformation on Points of Entry is Mandatory):',
      // 以下入口点位于伊拉克中部
      belowEntryPoints: 'The following points of entry are located in Central Iraq',
      note: 'Note: Goods shall be quickly delivered after issuance of the COC. Expiny dates (if any), remaining shelf life and validity of test reports (when required) shall be in compliance with applicable standards at the date of arrival (refer to appicable standards and "VOC Iraq - Notice to Exporters" for further details)',
      // 请选择装运方式
      pleaseSelectLoadingType: 'Please select a shipping method',
      pleaseSelectTransportMode: 'Please select a transport mode',
      // 包装数量及单位
      packingQuantityAndUnit: 'No. and type of packages:',
      // 包装数量
      packingQuantity: 'Number',
      // 包装单位
      packingUnit: 'Type of packages',
      // Letter of Credit
      letterOfCredit: 'Letter of Credit',
      // Bill of Lading
      billOfLading: 'Bill of Lading'
    },
    applicationSuccess: {
      returnIndex: 'Return to home page',
      submittedForReview: 'Submitted for review',
      reapply: 'application again',
      viewApplicationRecord: 'View application record'

    },
    exportProductCertification: {
      mailCertificateCollection: 'Mail certificate collection',
      businessInfo: 'Business Information',
      importer: 'Importer',
      exporter: 'Exporter',
      manufacturer: 'Manufacturer',
      factory: 'Factory',
      placeOfSurvey: 'Place Of Survey',
      VATNumber: 'VAT Number',
      paymentInfo: 'Payment Information',
      transportInfo: 'Transport Information',
      dateOfShipment: 'Date Of Shipment',
      loadingType: 'Loading Type',
      loadingPort: 'Loading Port',
      dischargingPort: 'Discharging Port',
      productInvoice: 'Product Invoice',
      uploadProductInvoice: 'Upload Product Invoice',
      invoiceInfo: 'Invoice Information (final invoice or proforma invoice)',
      noData: 'No Data',
      accessoriesOfAProduct: 'Accessories Of A Product',
      updateAccessoriesOfAProduct: 'Update Accessories Of A Product',
      saveToDraft: 'save to draft',
      oneClickTop: 'one click top'
    }
  },
  particular: {
    dwarTitle: 'Export certification and product certification',
    businessIntroduction: 'business Introduction',
    exportcertification: 'Export certification',
    text1: 'Export product clearance certification test',
    text2: 'Export certification is the certification of a product. In order to ensure the quality of imported products, more and more countries continue to introduce updated laws and regulations related to imported products, requiring importers and exporters to entrust third-party inspection and certification bodies to inspect products before shipment, and provide information for products that meet the standards of the importing country, so that the goods can be smoothly customs clearance in the importing country, otherwise they cannot be imported.',
    text3: 'As a result, more countries are implementing "compliance assessment schemes" (PVoC, CoC and other customs clearance certifications) as part of their sustainability plans. They issue certificates with slightly different names, but are virtually identical. It is enough to think of them as customs clearance. For example, Kenya (Clearance certification), Botswana (Clearance Certification), Egypt (called Inspection Certificate), Nigeria (called SONCAP Certificate, or SC), Uganda (Clearance).',
    text4: 'WSCT Trade and Institutional Services will rely on a professional team, rich experience in product compliance assessment, to assist and provide extensive, efficient and tailored solutions for the majority of export enterprises, to ensure that exporters goods meet the applicable standards and regulations of the importing country, to help your products smooth customs clearance.',
    mainProcess: 'Main Process',
    text5: 'Provide information to inspection and certification bodies such as WSCT for review and appointment of inspection.',
    text6: 'There may be some differences between different countries and exporting countries in terms of the specific information provided, but it is generally similar. For example: export to Nigeria: power of attorney (the organization provides templates for customers to fill in), packing list, test report (specific test report accepted standards, how to determine the report validity period with the organization), forms; Export to Kenya: Power of attorney (template provided by the organization for the customer to fill in), packing list, test report (specific test report accepted standards, how to determine the report validity period with the organization), IDF.',
    text7: 'Arrange inspection after approval',
    text8: 'Inspectors may inspect the goods after they have been produced and packed. In this process, inspectors will check the product packaging, randomly open several boxes to check the products inside, take photos and send the inspection report back to the company for review.',
    text9: 'Issue customs clearance.',
    text10: 'After inspection, the exporter should make corrections before shipment, if any. If there is no corrective opinion, the exporter shall issue it for the exporter to check. If there is no mistake, the exporter should send the original to the consignee at the destination port for customs clearance.'
  },
  policyInfo: {
    policy: 'Policies and regulations'
  },
  messageList: {
    title: 'Title',
    NoticeType: 'NoticeType',
    Readornot: 'Read or not',
    Date: 'Date',
    Messagedetails: 'Message details',
    center: 'Message center',
    Acceptor: 'Acceptor:',
    Data: 'Data:',
    Read: 'Read',
    Unread: 'Unread',
    more: 'More'
  },
  publicFile: {
    title: 'Public file download list'
  },
  // 产品检测申请
  Electronics: {
    productName: 'Name',
    code: 'Code',
    placeOrdersOnline: 'Place orders online and manage orders quickly',
    DetectionCategory: 'Detection Category',
    name: 'New application for testing electronic and electrical products',
    WSCTname: 'WSCT can conduct certification business in the following product range, please select your product',
    FillOutTheFormOnlineHint: 'Fill out the form online or call historical data and submit',
    draftsDetailTitle: 'Details of draft application',
    statusDetailTitle: 'Details of application processing',
    saveDraft: 'Save as draft',
    reportingRequirement: 'Reporting requirement',
    reportForm: 'Report form',
    reportFormtips: 'Select report form',
    reportCollection: 'Report collection',
    productRelatedInformation: 'Product related information',
    Title: 'Title',
    ProductNameCN: 'Product Name (Chinese)',
    ProductNameEN: 'Product Name (English)',
    productCategory: 'Product Category',
    ProductNameENtips: 'Please enter the product name',
    sampleQuantity: 'Sample quantity',
    SampleReturnOrNot: 'Sample return or not',
    ModelSpecificationCN: 'Model Specification (Chinese)',
    ModelSpecificationtips: 'Please enter the model specification',
    ModelSpecificationEN: 'Model specification (English)',
    Brand: 'Brand',
    uploadAttachment: 'Upload attachment',
    attachment: 'Attachment',
    uploadtips: 'Click Upload schematic diagram/PCB diagram/product specification/transformer specification/Model difference description/Contract/business license/others',
    fileType: 'File Type',
    fileName: 'File Name',
    Siez: 'Siez',
    State: 'State',
    Remake: 'Remake',
    Operate: 'Operate',
    textone: 'The size of each uploaded attachment should not exceed 30M; otherwise, it cannot be submitted normally。',
    texttwo: 'The supported file types are jpg、gif、png、bmp、doc、docx、xlsx、xls、pdf、txt、zip、rar。',
    Yes: 'Yes',
    No: 'No',
    NoWSCT: 'No (WSCT handles this by itself)',
    certificationProject: 'Certification project',
    detectionType: '（Detection type）',
    cProjectError: 'Please select a certification program',
    allParties: 'Basic information of each party',
    applicant: 'Mandator',
    manufacturer: 'Manufacturer',
    factory: 'Factory',
    companyCN: 'Company Name (Chinese)',
    companyENError: 'Please enter the company name',
    companyEN: 'Company name (English)',
    siteCN: 'Address (Chinese)',
    siteENError: 'Please enter the address',
    siteEN: 'Address (English)',
    contactName: 'Contact Name',
    contactNameError: 'Please enter the contact person',
    telephone: 'Telephone',
    telephonePlaceholder: 'Example: +86-10-8888888 or 86-010888888',
    telephoneError: 'Please enter telephone number',
    telephone11Error: 'Please enter the correct mobile phone number',
    email: 'Email',
    emailError: 'Please enter the correct email address',
    fax: 'Fax',
    chooseHistoricalData: 'Select historical data',
    identicalApplicant: 'Identical applicant',
    else: 'Else',
    serviceRequest: 'Service request',
    reportproductType: 'Please select the product type',
    // 用户条款
    userTerms: 'User terms',
    companyAddress: '，address，',
    ourCompany: 'our company，',
    userTermsStated: '，It is hereby stated that the declared products are not affected by the recalled products, nor are they unqualified or fake or shoddy products. We take responsibility for the authenticity of all declared information. At the same time, we are fully aware of the regulatory requirements of importing countries on the import of these products and are committed to complying with these requirements.',
    userTermsInfo: 'After submitting the information, WSCT will accept the application form and contact you via email as soon as possible. Please confirm whether this email address is used',
    submitSuccess: 'Submit success',
    submitError: 'Submit failure',
    SaveSuccess: 'Autosave success',
    SaveError: 'Autosave failure',
    DraftsSuccess: 'Save draft success',
    DraftsError: 'Save draft failure',
    titleTips: 'Is the name you give your application, so that you can query and distinguish between different applications. The title of two applications cannot be the same. You can use a way that is convenient for your own statistics and inquiries, and should not be too long. For example: 1) company name abbreviation-product abbreviation-number; 2) Trademark - Date - Number.',
    // 草稿
    DetectionType: 'Detection type',
    ApplicationCategory: 'Application category',
    warHis: 'Please select historical data',
    OTHERWing: 'Please fill in the other items',
    // 化学产品
    chemistry: {
      SampleInformation: 'Sample information',
      SampleName: 'Sample name',
      SampleNameErr: 'Please enter sample name',
      SampleNameCN: 'Sample name (Chinese)',
      SampleNameEN: 'Sample name (English)',
      Model: 'Model',
      ModelErr: 'Please enter the model number',
      ModelCN: 'Model number (Chinese)',
      ModelEN: 'Model number (English)',
      MatchCode: 'Sample code',
      batchNumber: 'Batch number',
      materials: 'Materials',
      materialsErr: 'Please input materials',
      TestSite: 'Test site',
      Brand: 'Brand',
      Bases: 'Sample reference information/main ingredients',
      TestItem: 'Test item',
      Applicationtestitems: 'Application for test items',
      ApplicationtestitemsErr: 'Enter/Select the test item',
      TestItemON1: 'Test Items and requirements: Be sure to provide samples of not less than the specified weight (or surface area) to avoid time loss due to insufficient samples.',
      TestItemON2: 'The preferred test method is buyer standard. If no buyer is provided, the following WSCT default reference test method is used.',
      TestItemON3: 'The meaning of reference test method is that the test is carried out by referring to the principle of the standard test method, and the relevant parameters (such as weighing sample volume, dilution ratio, etc.) are similar to but not necessarily identical with the standard test method.',
      TestItemON4: 'Accept and agree to the reference test method:',
      accept: 'Accept',
      objection: 'Please indicate any objection',
      SampleDetails: 'Sample details',
      SampleDetailsON1: 'The storage period of ordinary samples is 30 days. The storage period of liquid (such as paint, ink, glue, paste) and powder samples is 1 week after the report, and the samples will be destroyed after the deadline.',
      SampleDetailsON2: 'Whether the concentration of the substance to be measured is more than 1% of the total sample',
      SampleDetailsON3: 'No (Other testing methods shall be used if the concentration of the substance to be measured is more than 1% of the total sample, and it is not indicated that the deviation of the test results will be caused)',
      SampleDetailsON4: 'Do you accept subcontracting samples to other qualified companies?',
      SampleDetailsON5: 'Is there any legal dispute involved? If yes, please state:',
      riskText: 'The sample to be tested has',
      processMode: 'Sample handling mode',
      inspectionDate: 'Confirmed date with WSCT representative',
      SampleReturn: 'Sample return',
      RoHs: '1.RoHS test',
      RoHssize: 'Minimum sample size: 2',
      DEHP: '2. Phthalates',
      DEHPsize: 'Minimum sample size: 20g',
      PAHS: '3.PAHs polycyclic aromatic hydrocarbons',
      PAHSsize: 'Minimum sample size: 10g',
      battery: '4. Battery command',
      batterysize: 'Minimum sample size: 2',
      WEEE: '5.WEEE electronic product recycling',
      WEEEsize: 'Minimum sample size: complete machine',
      REACH: '6.REACH-SVHC',
      REACHsize: 'Minimum sample size: complete machine',
      packing: '7. Packing materials',
      packingsize: 'Minimum sample size: 20g',
      other: 'Other test items or requirements, please indicate:'
    },
    // 纺织品 鞋类 包
    textile: {
      SubdivisionCategory: 'Subdivision category',
      TextileStandardClass: 'Textile category',
      SubdivisionError: 'Please select a subcategory',
      standardError: 'Please select textile standard category',
      hintOne: '1. Please be sure to complete the items marked with * completely；',
      hintTwo: '2.Each application corresponds to one test report ；',
      reportForm: 'Report/certificate form',
      reportCollection: 'Report/certificate received',
      SampleName: 'Sample name',
      SampleNameErr: 'Please enter sample name',
      BrandName: 'Brand name',
      BrandNameErr: 'Please enter the trademark name',
      ModelNumber: 'Model number/model number',
      OrderNumber: 'order number',
      MaterialComposition: 'material composition',
      ProductGrade: 'product grade',
      ProductGradeErr: 'Please enter product grade',
      RequestedDate: 'Sample delivery quantity',
      RequestedDateErr: 'Please enter the sample quantity',
      SecurityCategory: 'Security category',
      SecurityCategoryErr: 'Please select a security category',
      DenimProductCategory: 'Denim product category',
      WoolProductsCategory: 'Wool products category',
      ResidualSampleProcessing: 'Residual sample processing',
      ResidualSampleProcessingErr: 'Please select residual sample processing',
      supplier: 'supplier',
      manufacturer: 'producer',
      purchaser: 'buyer',
      inspectionStandard: 'Inspection basis/test item',
      inspectionStandardT: 'Inspection basis',
      inspectionStandardErr: 'Please fill in at least one item for clothing, bedding and other products',
      TestItem: 'Test item',
      TestItemErr: 'Please select at least one item',
      Instruction: 'Care Instruction  (label)',
      physicalProperties: 'Physical property',
      colourFastness: 'Colour fastness',
      functionality: 'function',
      dimensionalStability: 'Dimensional stability',
      downProducts: 'Down products',
      chemicalProperties: 'Chemical property',
      ingredientsTest: 'Auxiliary material testing',
      otherTest: 'Please specify other test items',
      delivery: 'Report delivery method and requirements',
      ReportSelection: 'Report format selection',
      ReportCopy: 'Copy of report',
      conclusion: 'Test conclusion requirement',
      reportsNeeded: 'Paper reports are needed',
      NoreportsNeeded: 'There is no need for paper reports',
      Need: 'need',
      Noneed: 'with no need for',
      evaluate: 'evaluate',
      evaluatetext: 'No evaluation (if the entrusting party does not specify, it is deemed to agree to the selection of the agency)',
      exportingCountry: 'export to',
      exportingCountryErr: 'Please select an export destination',
      exportingCountryOth: 'Please fill in other exporting country',
      producingCountry: 'producing country',
      producingCountryOth: 'Please fill in other country of origin',
      TestAgeRange: 'Test age range',
      TestAgeRangeErr: 'Please select a test age range',
      TestAgeRangeOth: 'Please enter the test age range',
      scopeA: 'Yes, ask for an age range',
      scopeB: 'No, the test age is determined by WSCT',
      costume: 'costume',
      costumeOth: 'Please enter other clothing',
      beddingsOth: 'Please enter other bedding',
      otherProductsOth: 'Please enter other products',
      beddings: 'beddings',
      otherProducts: 'other products',
      standard: 'inspection standard',
      standardErr: 'Please enter other inspection criteria',
      standardText: 'Inspection criteria (if not selected, default to WSCT discretion)',
      CarryBag: 'Carry bag',
      luggageAndsuitcase: 'Travel suitcase',
      chemicalTest: 'Chemical test',
      OtherluggageAndbags: 'Other luggage and bags',
      OtherTestItems: 'Other test items',
      finishedShoes: 'Shoe 成品鞋',
      insole: 'Midsole 中底',
      Upper: 'Upper 鞋面',
      Outsole: 'Outsole 大底',
      Heel: 'Heel 鞋跟',
      Lace: 'Lace 鞋带',
      require: 'Select test items according to test requirements'
    }
  },
  // 产品检测办理情况
  PTAPlist: {
    PaymentStatus: 'Payment Status',
    HandlingStatus: 'Handling Status',
    SubmissionTime: 'Submission Time',
    DetectionType: 'Detection Type',
    ApplicationCategory: 'Application Category',
    ApplicationCode: 'Application Code',
    QuotationM: 'QuotationM'
  },
  // GCC
  GCC: {
    placeOrdersOnline: 'Place orders online and quickly manage orders',
    productRelatedInformation: 'Product related information',
    saveDraft: 'save as draft',
    Title: 'title',
    PleaseInput: 'Please input',
    PleaseSelect: 'Please select',
    ProductNameCN: 'Product Name (Chinese)',
    ProductNameEN: 'Product Name (English)',
    productCategory: 'Product Category',
    ProductNameENtips: 'Please input',
    sampleQuantity: 'Number of samples',
    SampleReturnOrNot: 'Is the sample returned',
    ModelSpecificationCN: 'Model and specifications (Chinese)',
    ModelSpecificationtips: 'Please input',
    ModelSpecificationEN: 'Model and specifications (English)',
    ProductSize: 'Product Size',
    TestStandardVersionNumber: 'Test standard/version number',
    TechnicalParameter: 'Technical Parameter',
    TestModeOfTheProduct: 'Test mode of the product',
    DoesTheProductApplyingForCertificationHaveACCCWSCTCBCertificate: '申请认证的产品是否有CCC/WSCT/CB证书 Does the product applying for certification have a CCC/WSCT/CB certificate',
    CCCWSCTCBCertificateNumber: 'CCC/WSCT/CB certificate number',
    NameOfTestingInstitution: 'Name of testing institution',
    Brand: 'Brand',
    CustomsCode: 'Customs Code',
    NewApplicationForCertification: 'GCC Certification New Application',
    uploadAttachment: 'upload attachment',
    attachment: 'attachment',
    uploadtips: 'Click to upload schematic diagram/PCB diagram/product manual/transformer specification book/model difference explanation/contract/business license/other',
    fileType: 'File Type',
    fileName: 'File Name',
    Siez: 'Siez',
    State: 'State',
    Back: 'Back',
    submit: 'submit',
    HistoryData: 'Select Historical Data',
    Remake: 'notes',
    Operate: 'operate',
    textone: 'The size of each uploaded attachment should not exceed 30M; Otherwise, normal submission will not be possible',
    texttwo: 'The supported file types are: jpg, gif, png, bmp, doc, docx, xlsx, xls, pdf, txt, zip, rar.',
    Yes: 'Yes',
    No: 'No',
    NoWSCT: 'No (WSCT handles it on its own)',
    certificationProject: 'Certification project',
    detectionType: '(Detection type)',
    cProjectError: 'Please select a certification project',
    allParties: 'Basic information of all parties',
    Applicant: 'Applicant',
    applicant: 'Principal (holder)',
    manufacturer: 'manufacturer',
    factory: 'Manufacturer',
    Client: 'client',
    Importer: 'importer',
    companyCN: 'Company Name (Chinese)',
    company: 'Company Name',
    companyENError: 'Please enter the company name',
    companyEN: 'Company Name (English)',
    siteCN: 'Address (Chinese)',
    siteENError: 'Please enter the address',
    siteEN: 'Address (English)',
    contactName: 'contacts',
    contactNameError: 'Please enter a contact person',
    telephone: 'phone',
    telephoneError: 'Please enter a phone number',
    telephone11Error: 'Cannot be less than four digits',
    telephone12Error: 'Can only consist of numbers, dashes (-), and plus signs (+), between 4 and 20 characters',
    email: 'email',
    emailError: 'Please enter the correct email address',
    fax: 'fax',
    chooseHistoricalData: 'Select Historical Data',
    identicalApplicant: 'Same as the principal',
    SameManufacturer: 'Same manufacturer',
    else: 'other',
    confirm: 'confirm',
    close: 'close',
    screen: 'screen',
    resetting: 'resetting',
    serviceRequest: 'Service requirements',
    serviceRequestErr: 'Please enter a different time for service requirements',
    reportproductType: 'Please select a product type',
    // 用户条款
    userTerms: 'User Terms',
    companyAddress: ', Address,',
    ourCompany: 'Our company,',
    userTermsStated: 'We hereby declare that the products declared this time are not affected by the recalled products, nor are they substandard or counterfeit products. We are responsible for the authenticity of all declared information. 同时，我们也深知进口国对这些产品进口的监管要求并承诺遵守这些要求。',
    userTermsInfo: 'After submitting the information, WSCT will accept the application form as soon as possible and contact you via email. Please confirm if this email is correct',
    submitSuccess: 'Submitted successfully',
    submitError: 'Submission failed',
    SaveSuccess: 'Successfully saved automatically',
    SaveError: 'Auto save failed',
    DraftsSuccess: 'Successfully saved draft',
    DraftsError: 'Failed to save draft',
    titleTips: 'It is the name you give to your application, which is convenient for you to search and distinguish between different applications. The application titles of two applications cannot be duplicate. You can name it in a way that is convenient for your own statistics and queries, and should not be too long. For example: 1) Company name abbreviation product abbreviation number; 2) Trademark date number.',
    CertificationCategory: 'Certification category',
    CertifiedProduct: 'Certified products',
    HSCode: 'customs code',
    TargetMarket: 'target market',
    relatedAttachments: ' Related accessories',
    RequestAdditionalInformation: 'Apply for additional information',
    productSize: 'Product size',
    testStandard: 'Test standard/version number',
    technicalSpecification: 'Technical Parameter',
    testingMode: 'Test mode of the product',
    certificateMode: 'Does the product applying for certification have a CCC/WSCT/CB certificate',
    CertificateNumber: 'CCC/WSCT/CB certificate number',
    CertificateNumberError: 'Please enter the CCC/WSCT/CB certificate number',
    detectionMechanism: 'Name of testing institution',
    detectionMechanismError: 'Please enter the name of the testing institution',
    InvoiceInformation: 'Invoice Information',
    ManageInvoiceInformation: 'Manage invoice information',
    CertificateMailingInvoiceInformation: 'Certificate/invoice mailing information',
    ManageMailing: 'Manage mailing information',
    invoiceType: 'Invoice Type',
    PleaseSelectInvoiceType: 'Please select invoice type',
    invoiceTitle: 'Invoice Title (Company Name)',
    site: 'address',
    bank_name: 'bank of deposit',
    bank_account: 'Bank account',
    taxpayer: 'Taxpayer Identification Number',
    back: 'Opening bank and account number',
    phone: 'Telephone',
    mobile: 'phone number',
    zipCode: 'zip code',
    mailingAddress: 'mailing address',
    addInvoiceInformation: 'Add invoice information',
    MailingOrReceivingCertificatesAndInvoices: 'Mailing or receiving certificates/invoices',
    // 填写认证申请书
    certificationCategory: 'Certification category',
    certificationCategoryName: 'GCC Certification New Application',
    selectProduct: 'WSCT can engage in certification business within the following product range. Please choose your product',
    selectProductTips: 'Please select your product',
    businessScope: 'business scope',
    warHis: 'Please select historical data',
    companyName: 'corporate name',
    AllParties: 'Basic information of all parties',
    product: 'Product related information'
  },
  entrustedInspection: {
    contactPhone: 'Contact phone',
    checkAddress: 'Check address',
    contractNo: 'Contract No',
    countryCodeOfProduct: 'Country code of the product',
    inputAddress: 'Please enter the full address',
    verificationDecription: 'Verification description',
    inputDescription: 'Please enter the verification description',
    selectCountryCode: 'Please select the country code of the product',
    selectStandard: 'Please select standard',
    choiceCriterion: 'Choice criterion',
    PreInspectionDate: 'Pre-inspection date',
    entrustedBaseInfo: ' Fill in the basic information of the entrusted inspection party',
    entrustedBaseInfo2: ' Basic information of the entrusted inspection party',
    inspectionServices: 'Inspection services',
    pleaseSelectServer: 'Select service',
    viewContract: 'View commissioned inspection contract',
    interStandard: 'International standard',
    countryStandard: 'Government standard',
    firmStandard: 'Company standard',
    clientStandard: 'Customer standard',
    personStandard: 'Individual standard',
    elseStandard: 'Other standards'
  },
  formLength: {
    maxLength255: 'Maximum length is 255 characters',
    maxLength500: 'Maximum length is 500 characters',
    maxLength100: 'Maximum length is 100 characters'
  },
  entrustedBusiness: {
    entrustedInspection: 'Entrusted inspection',
    entrustedDescribe: 'Entrusted inspection refers to an inspection organization with legal inspection qualifications entrusted by an enterprise to supervise and determine the quality of the products it produces and sells. The inspection agency inspects the product according to standards or contractual agreements, and issues an inspection report to the client. The inspection results are generally only responsible for the incoming sample.',
    entrustedDescribeTwo: 'WSCT Trade and Institutional Services will rely on a professional team and rich experience in product conformity assessment to assist and provide a wide range of efficient and customized solutions for export enterprises, to ensure that the exporter\'s goods meet the applicable standards and regulations of the importing country, and to help your products successfully complete the entrusted inspection.',
    mainProcesses: 'Main processes',
    processessOne: 'Provide information to inspection and certification bodies such as WSCT for review and appointment for inspection.',
    processessOneDescribe: 'In terms of the specific information provided, there may be some differences between different countries and exporting countries, but generally they are similar. For example: Export to Nigeria: Power of Attorney (template provided by the organization for customers to fill in), packing list, test report (specific test report acceptance criteria, and how to determine the report validity period with the organization), forms; Export to Kenya: Power of Attorney (template provided by the agency to the customer to fill in), packing list, test report (specific test report acceptance criteria, and how to determine the report validity with the agency), IDF.',
    processessTwo: 'Business acceptance.',
    processessTwoDescribe: 'Confirm the content of the agreement, sample status, signature, pricing, financial charges, invoicing, and other information, and go to the designated address to issue an inspection commission report based on the content signed in the agreement.',
    processessThree: 'After the inspection is completed, the relevant inspection department prepares the inspection report, which is reviewed and submitted to the approver for signature. Users can download the electronic version of the report in the WSCT client. If it needs to be mailed, the business management department can handle the mailing procedures on behalf of them; If there is any objection to the report, please contact the relevant business department of WSCT in a timely manner, and we will handle it in a timely manner.'
  },
  // 通知
  notice: {
    // 送样通知
    serialNumber: '申请流水号',
    certificationCategory: '认证类别',
    sendingTime: '发送时间',
    notificationNumber: '通知编号',
    applicationNo: '申请编号',
    applicationTitle: '申请标题',
    applicationCategory: '申请类别',
    bailor: '委托人',
    factory: '生产厂',
    productName: '产品名称',
    sender: '发送人',
    sendingDepartment: '发送部门',
    currentStage: '当前阶段',
    // 收样回执通知
    title: '标题',
    read: '是否已读',
    institutionOrdepartment: '发送机构/部门',
    type: '类型'
  },
  // 缴费记录
  paymentRecord: {
    // 未缴费记录
    title: '标题',
    preferentialPrice: '优惠后WSCT收费总额',
    settlementDepartment: '结算部门',
    settlementDate: '发费用结算单日期',
    // 我的汇款记录
    remittanceDate: '汇款时间',
    addRemittanceInformation: '新增汇款信息',
    remitter: '汇款人',
    remittanceAmount: '汇款单金额',
    currency: '币种',
    deleteTips: '确定删除当前记录吗？',
    totalRemittanceAmount: '汇款总金额',
    dateOfRemittance: '汇款日期',
    contacts: '联系人',
    contactPhone: '联系电话',
    postalCode: '邮政编码',
    address: '地址',
    remark: '备注',
    remittanceBasicInformation: '汇款基本信息',
    settlementList: '费用结算单列表',
    applicationNo: '申请编号',
    bailor: '委托人',
    factory: '生产厂',
    money: '金额',
    acceptanceDepartment: '受理部门',
    chargingStatus: '收费状态',
    valid: '本单是否有效',
    userPlaceholder: '用户名',
    amountCurrency: '金额币种',
    telephone: '电话',
    telephonePlaceholder: '请输入电话,例如:010-89718191',
    postcode: '邮编',
    remittanceBtn: '导入汇款信息',
    download: '下载',
    remittanceTips: '汇款信息模板',
    applicationNoBtn: '填写更多申请编号',
    applicationNoTips: '填写申请编号时，请填写完整的申请编号',
    remittanceInformation: '汇款信息',
    remitterTips: '请输入汇款人',
    dateOfRemittanceTips: '请选择汇款日期',
    contactsTips: '请输入联系人',
    contactPhoneTips: '请输入电话',
    // 费用通知
    serialNumber: '申请流水号',
    applicationTitle: '申请标题',
    notificationTime: '通知时间',
    applicationCategory: '申请类别',
    certificationCategory: '认证类别',
    productName: '产品名称',
    currentStage: '当前阶段',
    settlementType: '结算类型',
    paidExpenses: '已交费用',
    institutionPreferentialPrice: '检测机构收费总额优惠后',
    wsctPreferentialPrice: 'wsct收费总额优惠后',
    totalPreferentialPrice: '总费用优惠后',
    feeNotificationTime: '收费通知时间',
    immediateRemittance: '立即汇款'
  },
  communication: {
    scopeOfCertification: 'Scope of certification',
    selectTypeTip: 'Can engage in certification business in the following product range, your selected product is (single option)',
    selectScopeTitle: 'Select certification scope',
    selectScopeText: 'Relevant documents for applying for VOC certification for this type of product (please read carefully and operate according to relevant requirements)',
    certificateHolderTitle: 'Apply for a certificate holder (with',
    certificateHolderTitle2: 'Required fields)',
    applicant: 'Applicant',
    // 申请人名称
    applicantName: 'Name of applicant',
    // 制造商名称
    manufacturerName: 'Name of manufacturer',
    manufacturer: 'Manufacturer',
    producent: 'Producent',
    factoryInformation: 'Factory information',
    factoryName: 'Factory Name',
    coApplicant: 'copy applicant',
    coManufacturer: 'copy manufacturer',
    contactPerson: 'Contact person',
    website: 'Website',
    tradeLicense: 'commercial record (Trade License)',
    invoiceMailingAddress: 'Invoice Information and Mailing Address',
    productParameter: 'Product parameter accessories',
    productInfo: 'Product information',
    txtentOfTesting: 'Extent Of Testing',
    productName: 'Product Name ',
    mainNumber: 'M/N ',
    addingModel: 'Adding Model',
    tradeName: 'Trade Name',
    highestOperating: ' the highest operatingfrequency(such as: lC, Crystal, resonance......)',
    EUTOperatingTemperature: 'EUT Operating Temperature Range',
    EUTOperatingVoltage: 'EUT Operating Voltage Range',
    ratedVoltage: 'Rated Voltage',
    ratedPower: 'Rated Power',
    ratedCurrent: 'Rated Current',
    input: 'input',
    output: 'output',
    CEHardwareVersion: 'Hardware version NO(CE approval)',
    CESoftwareVersion: 'Software version NOCE approval)',
    adaptorMN: 'Adaptor M/N',
    batteryMN: 'Battery M/N',
    adaptorRatings: 'Adaptor Ratings',
    batterySpecification: 'Battery Specification',
    password: 'Password(approval for mobile phone, tablet PC)',
    protectionAgainst: 'Class of Protection against Electric Shock ',
    additionalInfo: 'Additional Information, please attach full details ',
    additionalInfoText1: '1. Click the "+" button and select Upload attachment.',
    additionalInfoText2: '2. The size of each uploaded attachment cannot exceed 10M, and a maximum of 5 files can be uploaded.',
    additionalInfoText3: '3. The supported file types are jpg, gif, png, bmp, doc, docx, xlsx, xls, pdf, txt, zip, and rar.',
    internationalScope: 'Applicable international standards',
    EUStandard: 'EU,African and Southeast Asian countries standards',
    USACanadaStandard: 'American,Canadian American standard',
    othersStandard: 'Other country specific standards',
    other: 'other',
    subcontract: 'Subcontract',
    isAcceptTest: 'We don\'t accept the test from test report outside WSCT ( If not selected, means accept subcontracting)',
    handlingInstruction: 'Handling instruction of samples',
    fetchByYourself: 'Fetch by yourself',
    toBeCollected: 'To be collected',
    toBeDestroyed: 'To be destroyed confidentially by WSCT',
    destroyedReminder: '* Note: If no any choice is made, all samples will be destroyed after storage. If applicants choose returning the sample, but don’t get the sample back within twelve month or don’t accept to pay courier fee on COLLECT, we assume applicants give up the sample, and WSCT is in charge to destroy the sample. ',
    specialVATInvoice: 'Special VAT Invoice',
    ordinaryVATInvoice: 'Ordinary VAT Invoice',
    proFormaInvoice: 'Proforma Invoice',
    taxpayerNumber: 'Taxpayer Identification Number',
    invoiceType: 'Invoice Type：',
    invoiceHeader: 'Invoice title (invoice name must match payment name)',
    bankCode: 'Bank Code',
    bankName: 'Bank Name',
    receiver: 'Receiver',
    applyingItem: 'Applying Item',
    newApplication: 'New application',
    modification: 'Modification',
    specifyReport: 'Please specify the original test report / Certificate number',
    standardUpdate: 'Standard Update',
    alternate: 'Alternate Components',
    alternateModels: 'Alternate models',
    grandeeCode: 'Grandee code',
    productCode: 'Product Code',
    burundiRpc: 'Burundi RPC',
    companyNo: 'Company No',
    CertifiedProduct: 'Certified product',
    inputAddress: 'Please enter the business address',
    addressa: 'Company address',
    inputAddressEn: '请输入公司地址（英文）',
    inputContact: 'Please enter a contact person',
    inputUrl: '请输入网址',
    inputTradeLicense: '请输入营业执照号码',
    pleaseInput: 'Please enter the content',
    pleaseInputCategory: 'Please select a certified product',
    pleaseInputScope: '请选择认证范围',
    pleaseCompleteForm: '请完善表单信息',
    pleaseInputCertificate: '请选择证书类型',
    pleaseSelectSampleHand: '请选择样品处理方式',
    pleaseSelectApplyType: '请选择申请类型',
    pleaseSelectStandard: '请选择适用国际规范',
    upload5Attachments: '提示：最多上传五个附件！',
    filesTips: '如有电子版资料，例如额定值或技术参数的文件，可以作为申请书的电子附件上传',
    pleaseSelectInvoiceType: '请选择发票类型',
    viewContract: '查看检测认证合同',
    invoceInfo: 'Invoice information',
    selectInvoceInfo: 'Select invoice information',
    invoceTitle: 'Invoice title (Company name)',
    invoceType: 'Invoice type',
    address: 'Address',
    taxNumber: 'Taxpayer identification number',
    bankAccount: 'Bank and account number',
    contactNumber: 'Contact number',
    contactPersonone: 'Contact person',
    certificateInvoice: 'Certificate/invoice sent or collected',
    certificateInvoiceInfo: 'Certificate/invoice mailing information',
    mailAddress: 'Mailing address',
    postcode: 'Postcode',
    companyName: 'Company name',
    selectMailInfo: 'Select mailing information',
    currentMailInfo: 'Current mailing information:',
    currentInvoiceInfo: 'Current invoice information:',
    invoceTitleCompany: 'Invoice title (invoicing company)',
    bankAccountInfo: 'Bank and account number',
    // 请选择邮寄地址
    pleaseSelectMailAddress: 'Please select a mailing address',
    // 产品网络制式
    productNetwork: 'Product network standard',
    // 声明
    declare: 'Declaration',
    // 我们声明
    weDeclare: 'We declare,that:',
    // 尚未向任何其他公告机构提交相同的合格评定申请。
    notSubmit: 'an identical application for conformity assessment has not been presented to any other Notified Body.',
    // 根据第10.2条，无线电设备可以在至少一个成员国运行，而不违反无线电频谱使用的适用要求。
    atLeastOne: 'in compliance with art. 10.2,  the Radio Equipment can be operated in at least one Member State without infringing applicable requirements on the use of the radiospectrum.',
    // RED（无线电设备指令）说明：
    redDeclare: 'RED(Radio Equipment Directive) notes：',
    // 根据2014/53/EU指令附件三进行合格评定
    redDeclare1: 'Conformity assessment in according to Annex III of the directive2014/53/EU regarding',
    // 第3.1a条
    redDeclare2: 'Article3.1a',
    // 第3.1b条
    redDeclare3: 'Article3.1b',
    // 第3.2条
    redDeclare4: 'Article3.2',
    // 第3.2条
    redDeclare5: 'Article3.3',
    // 健康保护，以及电气安全
    healthProtection: 'Protection of health,and Electrical safety',
    // 电磁兼容性
    electromatibility: 'EMC',
    // 频谱的有效使用
    spectrum: 'Effective use of the frequency spectrum',
    // 其它基本要求
    requirements: 'Other basic requirements'
  },
  thirdLogin: {
    scanCode: 'Scan', // Scan QR code
    scanCompleteRegist: 'Scan to complete the registration, you can log in',
    quickLogin: 'Quick login',
    registInfo: 'Registration information perfection',
    alipayLogin: 'Alipay login',
    thirdPartyLogin: 'Third party login'
  },
  NBCertificate: {
    choose: 'Choose', // 选择
    add: 'Add',
    sure: 'Sure',
    tips: 'Tips',
    delete: 'Delete',
    search: 'Search',
    reset: 'Reset',
    index: 'No.',
    upload: 'Upload',
    upload2: 'upload', // 上传
    import: 'Import', // 导入
    next: 'Next step', // 下一步
    cancel: 'Cancel', // 取消
    confirm: 'Confirm', // 确定
    edit: 'Edit', // 编辑
    warning: 'Warning',
    clear: 'Clear',
    back: 'Back', // 返回
    open: 'Open',
    close: 'Close',
    selectAll: 'Select all/Select none', // 全选/全不选
    confirmDelete: 'Are you sure you want to delete? ', // 确定要删除吗
    unknownError: 'unknown error',
    title: 'Title',
    noData: 'No data',
    startDate: 'Start Date',
    endData: 'End Date',
    toDate: 'To',
    noFile: 'No file',
    createForm: 'Create Form', // 创建表单
    firstStep: 'Select product category',
    secondStep: 'Fill in an application',
    thirdStep: 'Fill in additional information',
    certificateType: 'Certificate type',
    newApplication: 'Certification (New application)',
    modiflyApplication: 'Certification Update (Application for modification)',
    certificateNo: 'Certificate No.',
    placeOrdersOnline: 'Place orders online and manage orders quickly', // 在线下单，快捷管理订单
    businessGuide: 'Business application guide', // 业务申请指南
    applicationFor: 'Application For', // 申请类型
    selectFormTypeTitle: 'Reference information', // 参考信息
    FillOutTheFormOnlineHint: 'Fill in the form online or call historical data or download templates to fill it out before submitting', // 在线填写表格或调用历史数据或下载模板填写后提交
    pleaseInput: 'Please enter',
    pleaseSelect: 'Please select',
    // excel
    excelImport: 'Excel import', // 导入excel
    downloadTemplate: 'Download Template', // 下载模板
    uploadText: 'Click Or drag file here ', // 点击或者拖拽文件
    pleaseSelectProduct: 'Please select a product name', // 请选择产品名称
    contactAdmin: 'Application type not obtained, please contact the administrator', // 未获取到申请类型，请联系管理员
    // 历史数据
    importApplicationData: 'Example Import historical application data', // 导入历史申请数据
    chooseHistoricalData: 'Historical data', // 历史数据
    selectHistoryData: 'Please select historical application data',
    selectApplyType: 'Application type not obtained', // 未获取到申请类型
    pleaseUpdateTemplate: 'Please import an excel template',
    importExcel: 'The upload format is xlsc and the size does not exceed 20MB',
    RFCNo: 'RFC No.',
    createdTime: 'Created time',
    manufacturer: 'Manufacturer',
    applicant: 'applicant',
    draftsDetailTitle: 'Details of draft application',
    statusDetailTitle: 'Details of application processing',
    titlePrompt: 'It is the name you give to your application, so that you can easily search and distinguish between different applications. The application titles of two applications cannot be duplicated. You can name it in a way that is convenient for your own statistics and queries, and should not be too long. For example: 1) Company name abbreviation product abbreviation number; 2) Trademark date number',
    // 保存提示
    addApplicationSaveSuccess: 'Automatic saving succeeded', // 自动保存成功
    addApplicationSaveError: 'Failed to save automatically', // 自动保存失败
    addApplicationSubmitSuccess: 'Submit successfully', // 提交成功
    addApplicationSubmitError: 'Submit failure', // 提交失败
    addApplicationDraftsSuccess: 'Saved draft successfully', // 保存草稿成功
    addApplicationDraftsError: 'Save to draft failed', // 保存草稿失败
    saveSuccessfully: 'Save successfully', // 保存成功
    saveFailure: 'Save failure', // 保存失败
    currentApplicationCannotEdit: 'The status of the current certification application cannot be edited, please contact the salesman for operation', // 当前认证申请书状态不可编辑,请联系业务员进行操作
    sureSaveDraft: 'Are you sure to save the draft?',
    pleaseFillInSecondStep: 'Please fill in the second step application form information first', // 请先填写第二步申请表信息
    pleaseCompleteCertificateHolderInfo: 'Please fill the certificate holder information Required information', // 请完善证书持有人信息必填信息
    pleaseFillInfoCertificationScope: 'Please fill in the certification scope required information', // 请填写认证范围必填信息
    // 加载
    loading: 'Loading...',
    submitting: 'Submitting...',
    uploading: 'Uploading...',
    //  提示 完善表单必填信息
    completeApplicationContent: 'Please complete the required information for the order', // 请完善订单必填内容
    completeContent: 'Please complete the required content', // 请完善必填内容
    // Certificate Holder Information （will be recorded in certificate）
    certificateHolderInfo: 'Certificate holder information （will be recorded in certificate）',
    applicantInfo: 'Applicant information',
    applicantName: 'Name of the applicant',
    applicantNameU: 'Name\u00a0\u00a0of\u00a0\u00a0the\u00a0\u00a0applicant',
    addressName: 'Address of the applicant',
    eMail: 'E-mail',
    eMailU: 'E - mail',
    phoneNo: 'Phone No.',
    identificationDetails: 'Identification details of the applicant',
    identificationDetailsU: 'Identification\u00a0\u00a0details\u00a0\u00a0of the applicant',
    website: 'Website',
    representative: 'The applicant\'s representative authorized to contact the WSCT Polska Sp. z o.o.',
    nameSurname: 'Name surname',
    addressLine: 'Address\u00a0\u00a0\u00a0line',
    // Applicant status
    applicantStatus: 'Applicant status',
    productionPlantName: 'Name of production plant',
    productionPlantAddress: 'Address of production plant',
    choiceHistorialData: 'Choose historical data',
    pleaseSelectStatus: 'Please select Applicant status first', // 请先选择Applicant status
    //  Identification of the certification scope
    identification: 'Identification of the certification scope',
    chooseProduct: 'WSCT can engage in certification business within the following product range. Please choose your product:',
    radioDevice: 'The name of the radio device(Product)',
    productDescription: 'Product description',
    typeIdentification: 'Type identification',
    ModelNo: 'Model(s)',
    tradeMark: 'Trade mark',
    deviceType: 'Device type, batch or series number',
    // Standards and other technical specification
    standardsAndTenchnical: 'Standards and other technical specification',
    dyrektywa: 'Dyrektywa 2014/53/UE Moduł B / Directive 2014/53/UE Module B',
    essentialRequirements: 'Essential requirement(s)',
    appliedSpecifications: 'Applied Specifications / Standards',
    testReport: 'Test report(s)',
    selectManuallyInput: 'Select or manually input',
    reportInformation: 'Add or delete test report information through buttons',
    testReportNo: 'Test report No.',
    issuedDate: 'Issued date',
    issuedBy: 'Issued by',
    previous: 'Previous',
    saveDraft: 'Save as draft',
    // NB Evaluation Check List- RED 2014/53/EU
    evaluationCheck: 'NB Evaluation Check List- RED 2014/53/EU',
    attachmentsNote: 'Note: Attachments to the application, the corresponding template can be clicked ',
    No: 'No.',
    name: 'Name',
    remarks: 'Remarks',
    fileName: 'File name',
    size: 'Size',
    status: 'Status',
    operat: 'Operat',
    uploadSuccessfully: 'Upload successfully',
    uploadFailed: 'Upload failed',
    download: 'Download',
    filesTip1: '1. The size of each upload attachment cannot exceed 500M, otherwise it cannot be submitted properly.',
    filesTip2: '2. The supported file types are jpg gif、png、bmp、doc、docx、xlsx、xls、pdf、txt、zip、rar。',
    // Invoice Information and Mailing Address
    invoiceTitle: 'Invoice Information and Mailing Address',
    invoiceInfo: 'Invoice information',
    currentInvoiceInfo: 'Current invoice information: ',
    currentMailingInfo: 'Current mailing information: ',
    selectInvoice: 'Select invoice information',
    invoiceType: 'Invoice type',
    companyName: 'Company name',
    address: 'Address',
    identificationNumber: 'Identification number',
    bankAccount: 'Bank and account number',
    contacts: 'Contacts',
    telephone: 'Telephone',
    mailing: 'Certificate/invoice mailing or collection',
    mailingInfo: 'Certificate/invoice mailing information',
    selectMailing: 'Select mailing information',
    mailAddress: 'Mailing address',
    postalCode: 'Postal code',
    // Applicant’s declaration
    applicantsDeclaration: 'Applicant\'s declaration',
    place: 'Place, date',
    authorizedPerson: 'Name and surname of the authorized person to represent the applicant',
    signature: 'Signature',
    submit: 'Submit',
    // choice standard
    pleaseChoiceStandard: 'Please choice standards',
    choiceStandard: 'Choice standards',
    // Attachments
    attachments: 'Attachments to the application',
    attachmentsAccompanied: 'The application must be accompanied by (if applicable):',
    attachmentsAccompanied1: '1.Technical documentation of the radio device in written form.',
    attachmentsAccompanied1_2: '·The technical documentation contains the elements specified in Annex V to Directive 2014/53/EU.',
    attachmentsAccompanied1_3: '·The technical documentation should enable the assessment of the radio equipment in terms of its compliance with the applicable requirements of the directive and include an appropriate analysis and risk assessment.',
    attachmentsAccompanied1_4: '·The technical documentation shall specify the applicable requirements and cover, as far as is appropriate for such an assessment, the design, manufacture and operation of the radio equipment.',
    attachmentsAccompanied2: '2.Evidence of the adequacy of the technical design solution.',
    attachmentsAccompanied2_2: 'This evidence shall mention any relevant documents that have been used, in particular where the relevant harmonized standards have not been used at all or not in full.',
    attachmentsAccompanied2_3: 'Supporting evidence shall include, where appropriate, the results of studies carried out in accordance with others the relevant technical specifications by the manufacturer\'s appropriate laboratory or by another testing laboratory on his behalf and under his responsibility.',
    attachmentsAccompanied3: '3.A current extract from the relevant court register or the manufacturer\'s business register, and in the case of an authorized representative, also a current extract from the relevant court register or business activity records of this representative (not older than 3 months).',
    attachmentsAccompanied4: '4.Power of attorney to represent the manufacturer if the application is submitted by an authorized representative of the manufacturer.',
    attachmentsAccompanied5: 'Notified Body provides all information and explanations related to completing the application.',
    templateDownload: 'Template Download',
    documentMessage01: '1、The size of each uploaded attachment should not exceed 500MB; otherwise, it cannot be submitted normally.',
    documentMessage02: '2、The supported file types are jpg、gif、png、bmp、doc、docx、xlsx、xls、pdf、txt、zip、tar、rar.',
    uploadTip: 'Upload the format of JPG/GIF, PNG/BMP/doc/docx/XLSX/XLS/PDF/TXT/zip/tar/rar and size does not exceed 500 MB',
    uploadSuccess: 'Upload successful',
    limitFilesNum: 'Upload up to 20 attachments per order', // 每次最多上传20个附件
    uploadError: 'Upload failed',
    toModify: 'To modify',
    haveFailDocument: ' attachments are being uploaded or uploading failed. Are you sure to submit?',
    reupload: 'Reupload',
    // 附件信息
    selectFileType: 'Please select a file type',
    pleaseUploadFile: 'Please upload the attachment',
    // 用户条款
    userTerms: 'User terms',
    companyAddress: ', The address is ',
    ourCompany: 'Our company, ',
    userTermsStated: ', It is hereby stated that the declared products are not affected by the recalled products, nor are they unqualified or fake or shoddy products. We take responsibility for the authenticity of all declared information. At the same time, we are fully aware of the regulatory requirements of importing countries on the import of these products and are committed to complying with these requirements.',
    userTermsInfo: 'After submitting the information, WSCT will accept the application form and contact you via email as soon as possible. Please confirm whether you can be reached by this email address ',
    usreTermsInfo2: '. If not, please fill in below, multiple email address with ";" Separate.',
    noObtainedOrder: 'Order information not obtained' // 未获取到订单信息
  },
  // NB邮寄信息
  nbPostInfo: {
    recipient: 'Recipient',
    address: 'Address',
    companyName: 'Company name',
    contactNumber: 'Contact number',
    mailingAddress: 'Mailing address',
    postcode: 'Postcode',
    addMailInformation: 'Add mailing information',
    editMailInformation: 'Edit mailing information',
    deleteMailInformation: 'Delete mailing information',
    selectMailing: 'Please select mailing informations',
    sureDeleteMailing: 'Are you sure to delete the selected mailing information?'
  },
  configurationManagement: {
    InvoiceTitle: '(Invoice header)',
    address: 'Address',
    invoiceType: 'Invoice Type',
    openAccountBank: 'Opening Bank',
    account: 'Account',
    contactNumber: 'Contact phone number',
    contact: 'Contact person',
    bankAccount: 'Bank account number',
    Status: 'Status',
    sort: 'Sort',
    operation: 'Operation',
    productName: 'Product name',
    productNumber: 'Product number',
    CertificationItemNumber: 'Certification item number',
    CertificationItemName: 'Certification item name',
    pleaseInputItemNumber: 'Input certification item number',
    pleaseInputItemName: 'Input certification item name',
    SelectInvoiceIn: 'Please select the invoice information you want to delete!',
    sureDeleteInvoice: 'Are you sure you want to delete this invoice information?',
    selectMailing: 'Please select the mailing information you want to delete!',
    sureDeleteMailing: 'Are you sure you want to delete this mailing information?',
    pleaseInputCompanyName: 'Please enter the company name',
    pleaseInputProductName: 'Please enter the product name',
    pleaseInputProductNumber: 'Please enter the product number',
    pleaseInputStatus: 'Please select status',
    pleaseInputAddress: 'Please enter the address',
    pleaseInputContact: 'Please enter the contact person',
    pleaseInputTaxpayer: 'Please enter the taxpayer identification number',
    pleaseAccountBank: 'Please enter the account opening bank',
    pleaseInvoiceType: 'Please select an invoice type',
    pleaseBankAccount: 'Please enter your bank account number',
    pleaseInputPhone: 'Please enter phone number',
    addMailInformation: 'Add mailing information',
    editMailInformation: 'Edit mailing information',
    addInvoiceInformation: 'Add invoice information',
    editInvoiceInformation: 'Edit invoice information',
    laboratoryNumber: 'Laboratory Number',
    laboratoryName: 'Laboratory name',
    laboratoryType: 'Laboratory Type',
    remarks: 'Remarks',
    createTime: 'Create time',
    updateTime: 'Update time',
    pleaseLaboratoryName: 'Please enter the laboratory name',
    pleaseLaboratoryType: 'Please enter the laboratory type',
    sureDelete: 'Are you sure you want to delete it?',
    companyName: 'Company Name',
    pleaseUploadFile: 'There are currently attachments with a review status of returned. Please delete them and upload them again!',
    pleaseUploadReport: ' Please upload the report',
    pleaseInputInt: 'Please input positive integer',
    pleaseInputNum: 'Please input number',
    sureDeleteProduct: 'Are you sure you want to delete this product information?',
    protocolName: 'Standard protocol name',
    open: 'Open',
    close: 'Close',
    createPerson: 'Create person',
    updatePerson: 'Update person',
    config: 'Configuration',
    clause: 'Clause',
    sequence: 'Sequence',
    importFile: 'Import standard file',
    uploadTemplate: 'Download template',
    fileHint: 'Please upload no more than 300M EXCEL files',
    testWarring: 'There may be overwriting when importing standard files. Are you sure to upload?',
    testRequirements: 'Test items and requirements',
    onlineConfig: 'Report standard protocol configurations online',
    configInfor: 'Please select the configuration information you want to delete!',
    pleaseUploadFileFirst: 'Please upload the standard file first',
    cancelConfirm: 'Are you sure you want to delete this report configuration? It will not be restored after deletion!',
    cancelConfirmFile: 'Are you sure you want to remove this standard protocol? It will not be restored after deletion!',
    changeFile: 'Standard protocol automatic saving success! If you need to modify, please click the edit button on the right side of the list to modify!',
    amKnow: 'I already know',
    updateName: 'Standard protocol name modified successfully!',
    taxpayerNumber: 'Taxpayer Identification Number',
    delInVoice: 'Delete invoice information',
    delMail: 'Delete mailing information'
  },
  // 沙特
  SaudiArabia: {
    // 各方信息
    InformationParties: 'Information of All Parties',
    manufacturerDetails: 'Manufacturer Details',
    importerDetails: 'Importer/Authorized Representative Details',
    factoryDetails: 'Name of Factory Details',
    inspectionPlace: 'Place of Inspection',
    importer: 'Importer',
    clear: 'Clear',
    chooseHistoricalData: 'Choose historical data',
    copyManufacturer: 'Copy Manufacturer',
    copyImporter: 'Copy Importer',
    country: 'Country',
    SCNo: 'SC No.',
    companyNameEn: 'Company Name(EN)',
    city: 'City',
    address: 'Address',
    contactName: 'Contact Name',
    telephone: 'Telephone',
    telephonePlaceholder: 'Example: +86-10-8888888 or 86-010888888',
    email: 'Email',
    warehouseName: 'Warehouse Name',
    warehouseNameEn: 'Warehouse Name(EN)',
    zoneProvince: 'Zone/Province',
    GoodsAvailableDate: 'Goods Available For Inspection As From',
    pleaseSelectRfcType: 'Please select the Application For first',
    // 产品信息
    productInfo: 'Product Information',
    productName: 'Product Name ',
    trademarkBrand: 'Trademark/Brand',
    typeModel: 'Type/Model No.',
    HSCode: 'HS(tariff) code',
    otherInformation: 'Other information',
    otherAccessory: 'Other Accessory',
    // 附件
    addTransactionDocument: 'Add Transaction Document',
    AttachmentsAreRequired: 'Attachments are required: the previous PCOC/SC certification agreement form, Importer Declaration of Conformity (DOC), manufacturer business registration certificate, product safety test report, etc., can be downloaded/viewed by clicking ', // 需提供附件：有前面的PCOC/SC认证协议表格，进口商合格申明（DOC），制造商商业登记证，产品安全测试报告等，可点击“”下载/查看
    fileType: 'File Type',
    pleaseSureApplicationInfo: 'Please be sure to take the information of the application form seriously, to avoid misreporting and testing progress, please be sure to fill in carefully. (After filling in, give a signed and stamped PDF file at the same time)' // 请一定要认真对待申请表信息，避免眈误报告和测试的进度，请一定要认真填写。(填写后，同时要给到签字盖章的PDF档)
  }
}
