/**
 * 字典
 */
import { createAxios } from '@/utils/request'

const baseUrl = process.env.VUE_APP_URL_BASE
const requestBase = createAxios(undefined, baseUrl)

const slugList = [
  // NB相关
  'wireless_communication_nb_rfc.app_type', // NB证书类型 NEW MODIFY
  'wireless_communication_nb_rfc_standard.article', // 标准类型
  'laboratory_rfc.cert_send_method',
  'addedvaluetax.type', // 发票类型
  'wireless_communication_rfc_info.cert_send_method', // 证书寄送方式
  'wireless_communication_nb_rfc_file.type', // 附件类型
  'wireless_communication_nb_rfc_standard_item.issued_by', // 签发人
  'client.apply.status', // 申请状态
  // 出口认证
  'rfc.apply_type', // 申请类型
  'rfc.client.apply.file.type', // 附件类型
  'client.rfc.rfc_status' // 申请状态
]

// 所有字典
export const getDictionary = () => {
  return requestBase({
    method: 'GET',
    url: `/dictionary?slugs=${slugList}`
  })
}
// NB发证
// 检测任务 任务类型
export const acceptStatus = { 1: '受理', '-1': '退回' }
// 样品是否返回
export const sampleNeedBackList = { '-1': '否（WSCT自行处理）', 1: '是' }
// 检测状态是否异常
export const isAbnormal = { 1: '是', 2: '否' }
// 检验结果审核状态
export const detectionStatus = { 0: '待初审', 1: '审核通过', 2: '退回', 3: '待复审' }
// 报告文件类型
export const reportTypeList = { 1: '检测报告' }
// 检测数据查询条件 是否异常
export const reportIsAbnormal = { 1: '是', 0: '否' }
// 检测任务 任务类型
export const examiningType = { 1: '常规', 2: '分包' }
// 检测任务 签派负责人 服务类型
export const serverType = { 1: '证书受理' }
// 配置管理 产品认证状态
export const productsType = { '-1': '禁用', 1: '启用' }
// 申请类型
export const applicationType = { 129: 'NB证书（RED）' }
